<template>
  <div>
    <p class="ma-0">{{ $t('endpoint_service_info_1') }}</p>
    <p>{{ $t('endpoint_service_info_2') }}</p>

    <v-row dense>
      <v-col>
        <h4>{{ $t('protocol') }}</h4>
        <span class="caption">{{ $t('select_protocol') }}</span>
      </v-col>
      <v-col cols="5">
        <h4>{{ $t('port') }}</h4>
        <span class="caption">{{ $t('specify_port_list') }}</span>
      </v-col>
      <!-- added custom minWidth to accomodate delete action button -->
      <v-col v-if="settingsCopy.items.length > 1" class="flex-grow-0 px-0" style="min-width: 36px"> </v-col>
    </v-row>

    <object-service-item
      v-for="(item, index) in settingsCopy.items"
      :key="index"
      :protocol.sync="settingsCopy.items[index].protocol"
      :port.sync="settingsCopy.items[index].port"
    >
      <!-- using the `actions` slot to add a delete condition button -->
      <template v-if="settingsCopy.items.length > 1" #actions>
        <v-btn icon @click="onRemoveItem(index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </object-service-item>

    <v-btn depressed outlined class="text-capitalize primary--text mt-2" @click="onAddItem">
      <v-icon small class="mr-1">mdi-plus</v-icon> {{ $t('add') }}
    </v-btn>
    <v-spacer />
    <slot name="actions" :new-settings="settingsCopy" />
  </div>
</template>
<script>
  import isEqual from 'lodash/isEqual'
  import cloneDeep from 'lodash/cloneDeep'
  import ObjectServiceItem from './ObjectServiceItem.vue'

  export default {
    components: { ObjectServiceItem },
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: [{ port: '[23', ...], protocol: ['21',...] },...],
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },

    data() {
      return {
        settingsCopy: undefined,
      }
    },

    // using watchers to initialize/update the service endpoints objects
    watch: {
      settings: {
        handler(settings) {
          if (isEqual(settings, this.settingsCopy)) return
          this.settingsCopy = cloneDeep(settings)
        },
        immediate: true,
      },
      // make sure the settings have a port+protocol structure, if it's empty
      settingsCopy: {
        handler(settings) {
          if (!settings) return
          if (!settings.items.length) {
            this.settingsCopy.items.push({ port: [], protocol: [] })
          }
        },
        immediate: true,
      },
    },

    methods: {
      /** Adds a new port/protocol item */
      onAddItem() {
        this.settingsCopy.items.push({ port: [], protocol: [] })
      },

      /** Removes a port/protocol item at the specified index */
      onRemoveItem(index) {
        this.settingsCopy.items.splice(index, 1)
      },
    },
  }
</script>

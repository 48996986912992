<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 ma-4">
    <h1 class="headline">{{ $t('assignment') }}</h1>
    <span class="caption my-2">{{ $t('assignment_description') }}</span>
    <v-divider class="mb-4" />
    <div class="d-flex align-center mb-4">
      <u-btn
        :disabled="selectedAppliances.length === 0"
        class="mr-2"
        @click="$router.push({ name: 'pm-provisioning', params: { selectedAppliances: selectedAppliances } })"
        >{{ $t('provision') }}</u-btn
      >
      <u-btn :disabled="!selectionHasPolicies" class="mr-2" @click="syncSelectedAppliances">{{ $t('sync_now') }}</u-btn>
      <u-btn :disabled="!selectionHasPolicies" class="mr-2" @click="confirmUnassignPolicies">
        {{ $t('unassign_policies') }}
      </u-btn>
      <u-btn :disabled="!enableViewRules" class="mr-2" @click="showRuleDetails">
        {{ $t('view_rules') }}
      </u-btn>
      <u-btn :disabled="selectedAppliances.length !== 1" @click="compareAppliancePolicies"> {{ $t('compare') }} </u-btn>
    </div>
    <u-grid
      id="appliances-grid"
      :key="'appliance'"
      class="provisioning-grid"
      selection-type="multiAction"
      :no-data-message="noDataMessage('assignments')"
      :column-defs="applianceColumnDefs"
      :fetching="fetching"
      :row-data="appliances"
      :selection.sync="selectedAppliances"
      :framework-components="frameworkComponents"
      @refresh="fetchData(true)"
    >
    </u-grid>

    <!-- list all rules for appliance-->
    <u-dialog
      :show-dialog="showRules"
      :retain-focus="false"
      width="90%"
      :title="$t('show_rules')"
      :buttons="[
        {
          'name': $t('ok'),
          'handler': 'close-dialog',
        },
      ]"
      @close-dialog="closeRulesDialog"
    >
      <div style="height: 75vh" class="d-flex mb-n4">
        <u-grid
          id="rules"
          :no-data-message="noDataMessage('rules')"
          :column-defs="rulesColumnDefs"
          :row-data="rules"
          :fetching="rulesFetching"
          :selection.sync="rulesSelection"
          :framework-components="frameworkComponents"
          @refresh="fetchRulesData(true)"
        />
      </div>
    </u-dialog>

    <!-- json diff dialog -->
    <u-dialog :show-dialog="showDiff" width="90%" :title="$t('compare')" @close-dialog="showDiff = false">
      <h3 class="mb-4 ml-1">{{ $t('compare_description') }}</h3>
      <div style="height: 75vh" class="d-flex mb-n4">
        <code-diff
          language="json"
          :old-string="JSON.stringify(currentSettings, null, '\t')"
          :new-string="JSON.stringify(newSettings, null, '\t')"
          output-format="side-by-side"
          class="ma-0"
          :theme="$vuntangle.theme"
        />
      </div>
    </u-dialog>
  </div>
</template>
<script>
  import { columnDefs, ActionRenderer, AssociatedPolicyRenderer, ConditionsRenderer, NameRenderer } from 'vuntangle/pm'
  import { CodeDiff } from 'v-code-diff'
  import { getPolicyManagerAppliances } from '../util'
  import { hydrateRulesData } from '../hydration'
  import renderersMixin from '../renderersMixin'
  import grids from '@/plugins/ut/ut-grids'
  import api from '@/plugins/ut/ut-api'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { CodeDiff },
    mixins: [renderersMixin],
    data() {
      return {
        selectedAppliances: [],
        rulesSelection: [],
        rulesFetching: false,
        rules: [],
        showRules: false,
        showDiff: false,
        currentSettings: null,
        newSettings: null,
        frameworkComponents: {
          ConditionsRenderer,
          ActionRenderer,
          AssociatedPolicyRenderer,
          NameRenderer,
        },
      }
    },
    computed: {
      fetching: ({ $store }) => $store.state.appliances.fetching || $store.state.appliances.fetchingUnassigned,
      appliances: () => getPolicyManagerAppliances(),
      applianceColumnDefs: ({ $store, augmentColumns }) =>
        augmentColumns(
          grids.getPMAppliancesColumnDefs(
            $store.state.data.ccViewModel.Account.NoLicenseEnforcement ? ['license'] : [],
          ),
          ['policies', 'global_templates'],
        ),

      /** flag to enable/disable the `Unassign Policies` or `sync now' if any of the selected appliances do have policies assigned */
      selectionHasPolicies: ({ selectedAppliances }) => selectedAppliances.some(appl => appl.Policies?.length),
      rulesColumnDefs: ({ augmentColumns }) => augmentColumns(columnDefs.getApplianceRulesColumnDefs(), ['action']),
      /** flag to enable/disable the `View Rules`; enable if selected one appliance and appliance has at least one policy assigned  */
      enableViewRules: ({ selectedAppliances, selectionHasPolicies }) =>
        selectedAppliances.length === 1 && selectionHasPolicies,
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      /**
       * Gets appliance data
       *
       * @returns {Promise<void>}
       */
      fetchData(force = true) {
        this.$store.dispatch('appliances/fetchFullAppliances', { force })
        this.$store.dispatch('policyManager/getAllApplianceAssignments', { force })
        this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force })
      },
      /**
       * Gets rules data
       */
      async fetchRulesData() {
        this.rulesFetching = true
        await this.$store.dispatch('policyManager/getDataForAppliancePoliciesWidget', this.selectedAppliances[0].Uid)
        this.rulesFetching = false
      },

      /**
       * Syncs the selected appliances
       */
      async syncSelectedAppliances() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const uids = this.selectedAppliances.filter(appl => appl.Policies?.length).map(appliance => appliance.Uid)
        const response = await this.$store.dispatch('policyManager/syncAppliancePolicies', uids)
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response) {
          this.$vuntangle.toast.add(this.$t('policy_syncing_initiated'))
          this.selectedAppliances = []
          this.fetchData()
        }
      },

      /**
       * Shows confirmation dialog upon unassign policies action
       */
      confirmUnassignPolicies() {
        this.$vuntangle.confirm.show({
          title: this.$t('unassign_policies'),
          message: this.$t('unassign_policies_warning'),
          confirmLabel: this.$t('yes'),
          cancelLabel: this.$t('no'),
          action: async resolve => {
            const response = await this.unassignPolicies()
            if (response) {
              this.selectedAppliances = []
              this.$vuntangle.toast.add(this.$t('policy_syncing_initiated'))
              this.fetchData()
            }
            resolve()
          },
        })
      },

      /**
       * Dispatches the action to unassign all policies from the selected appliances
       */
      async unassignPolicies() {
        /**
         * collect appliances that do have policies to be unassigned
         * to avoid creating unnecessary tasks
         */
        const applianceIds = this.selectedAppliances
          .filter(appl => appl.Policies?.length)
          .map(appliance => appliance.Uid)

        const response = await this.$store.dispatch('policyManager/updateApplianceAssignments', {
          assignments: [],
          applianceIds,
        })

        return response
      },
      /**
       * List all rules for specific appliance
       */
      async showRuleDetails() {
        this.$store.commit('SET_PAGE_LOADER', true)
        await this.$store.dispatch('policyManager/getDataForAppliancePoliciesWidget', this.selectedAppliances[0].Uid)
        const rules = this.$store.getters['policyManager/getAllRulesForAppliance']
        const policies = this.$store.getters['policyManager/getAllPoliciesForAppliance']
        if (rules) {
          this.rules = hydrateRulesData(rules, policies)
          this.showRules = true
        }
        this.$store.commit('SET_PAGE_LOADER', false)
      },

      /**
       * Closes the rules dialog and handles any clean up
       */
      closeRulesDialog() {
        this.showRules = false
        this.selectedAppliances = []
      },
      noDataMessage(gridType) {
        return (gridType === 'rules' && this.rules.length > 0) ||
          (gridType === 'assignments' && this.appliances.length > 0)
          ? this.$t('no_filtered_data_' + gridType)
          : this.$t('no_data_defined_' + gridType)
      },

      /**
       * Calls backend to fetch the current settings on the box,
       * and a preview for the assigned policies on the box
       *
       * @returns {Promise<void>}
       */
      async compareAppliancePolicies() {
        if (this.selectedAppliances.length !== 1) return
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await api.cloud('Untangle_CommandCenter', 'GetApplianceSettingsForCompare', {
          uid: this.selectedAppliances[0].Uid,
          paramOrder: 'uid',
        })
        this.$store.commit('SET_PAGE_LOADER', false)
        if (response.success && response.data) {
          this.currentSettings = response.data.currentSettings
          this.newSettings = response.data.newSettings
          this.showDiff = true
        } else {
          this.$vuntangle.toast.add(i18n.t('compare_no_data'), 'error')
        }
      },
    },
  }
</script>

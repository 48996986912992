import { set } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { SyncStatus, Type } from 'vuntangle/pm'
import { findMFWTypesByPrefix, getPoliciesByDependencyId } from '../pages/policies/mfw/policy-manager/util'
import DeleteDependencies from '../pages/policies/mfw/policy-manager/components/DeleteDependencies.vue'
import api from '@/plugins/ut/ut-api'
import vuntangle from '@/plugins/vuntangle'
import i18n from '@/plugins/vue-i18n'

const getDefaultState = () => {
  return {
    /**
     * everything that's fetched from mongo will be stored as an object (policy/rule/condition/policy object)
     * all the objects will be fetched with PolicyJson and stored by etm type as key
     * e.g.
     *   obejcts: {
     *     'mfw-object-condition': [...all conditions objects],
     *     'mfw-policy': [...all policies],
     *   }
     * see policy-manager/constants.js for etm types definitions
     * using key base store to avoid fetching all data at once and update store incrementally
     * while data is fetched
     * */
    objects: {},
    fetching: {},
    fetched: {},
    policyMap: {},
    assignment: [],
    /** keeps track of objects being edited to avoid mutating original fetched data */
    editObjects: [],
    outOfSyncAppliances: 0,
    appliancePolicies: [],
    applianceRules: [],
    analytics: [],
    objectDialog: {
      object: undefined, // the object to be edited
      cb: undefined, // callback passing response after object/groups is saved
    },
    configurationDialog: {
      configuration: undefined, // the configurations to be edited
      cb: undefined, // callback passing response after configuration is saved
    },
  }
}

const getters = {
  getObjects: state => state.objects,
  getObjectsByType: state => type => state.objects[type] || [],
  getObjectsByTypeIds: state => (type, ids) =>
    ids?.map(id => state.objects[type]?.find(object => object.Id === id)).filter(object => object),
  getObjectById: state => id => {
    let object
    Object.values(state.objects).forEach(types => {
      if (object) return
      object = types.find(o => o.Id === id)
    })
    return object
  },
  getObjectsByIds: state => ids => {
    const allObjects = Object.values(state.objects).flat() || []
    return ids?.map(id => allObjects.find(object => object.Id === id)).filter(object => object)
  },
  getApplianceAssignment: state => state.assignment,
  getAllPoliciesForAppliance: state => state.appliancePolicies,
  getAllRulesForAppliance: state => state.applianceRules?.filter(rule => rule.Type !== Type.RuleWanPolicy) || [], // CD-6048 avoid listing WAN Rules
  fetching: state => type => state.fetching[type],

  /** returns an object being edited by it's id */
  getEditObjectById: state => id => state.editObjects.find(object => object.Id === id),
  /** returns the new rule object being edited */
  getRuleEditObject: state => state.editObjects.find(object => object.Id.startsWith('mfw-rule')),

  /** returns an array of policyIds that depend on the objectId passed
   * Array.from() is used to return an array instead of a Set
   * use util.getPoliciesByDependencyId() to get full policy objects */
  getPolicyIdsByDependencyId: state => id => Array.from(state.policyMap[id] || []),

  getOutOfsyncCount: state => state.outOfSyncAppliances,

  getAnalytics: state => state.analytics,
}

const mutations = {
  RESET: state => Object.assign(state, getDefaultState()),

  /**
   * Removes objects from the store
   * @param {Array} objects - array of the objects to be removed from store
   */
  DELETE_OBJECTS: (state, objects) => {
    objects.forEach(object => {
      const objectType = object.Type
      const stateObjects = state.objects[objectType]
      const index = stateObjects?.findIndex(o => o.Id === object.Id)
      if (index >= 0) stateObjects.splice(index, 1)
    })
  },

  /**
   * Takes a new map (generated in the backend)
   * and merges it with the existing policyMap object
   * @param newMap - objectIds (rule, condition, etc.) as the key, array of policyIds as the value
   */
  UPDATE_POLICY_MAP: (state, newMap) => {
    for (const [objectId, policyIdArray] of Object.entries(newMap)) {
      const policyIdSet = state.policyMap[objectId]
      if (policyIdSet === undefined) {
        state.policyMap[objectId] = new Set(policyIdArray)
      } else {
        policyIdArray.forEach(policyId => policyIdSet.add(policyId))
      }
    }
  },
  /**
   * deletes the key 'object.Id' from policyMap
   * @param objects - a list of objects being deleted
   */
  DELETE_POLICY_FROM_MAP: (state, objects) =>
    objects.forEach(object => {
      Object.values(state.policyMap).forEach(entry => entry.delete(object.Id))
    }),

  SET_OBJECTS_KEY: (state, type) => {
    set(state.objects, type, [])
    set(state.fetched, type, true)
  },

  /**
   * Adds or updates objects to the store by their specific type
   */
  UPDATE_OBJECTS: (state, { objects, fetched = true }) => {
    /** update existing object or add it if it's new  */
    objects.forEach(object => {
      const objectType = object.Type
      // create object key placeholder if not exists yet in the store
      if (!state.objects[objectType]) set(state.objects, objectType, [])

      // find and update the object, otherwise add it to store
      const index = state.objects[objectType].findIndex(o => o.Id === object.Id)
      if (index >= 0) state.objects[objectType].splice(index, 1, object)
      else state.objects[objectType].push(object)
      set(state.fetched, objectType, fetched)
    })
  },
  SET_FETCHING: (state, { objectType, value }) => set(state.fetching, objectType, value),
  SET_ASSIGNMENT: (state, assignment) => {
    set(state, 'assignment', assignment)
    // update outOfSyncAppliance count
    const unsyncedAppliances = assignment.reduce((unsynced, { appliance_id: uid, sync_status: syncStatus }) => {
      if (syncStatus === SyncStatus.NotSynced && !unsynced[uid]) {
        unsynced[uid] = true
      }
      return unsynced
    }, {})
    set(state, 'outOfSyncAppliances', Object.keys(unsyncedAppliances).length)
  },
  SET_APPLIANCE_POLICIES: (state, appliancePolicies) => set(state, 'appliancePolicies', appliancePolicies),
  SET_APPLIANCE_RULES: (state, applianceRules) => set(state, 'applianceRules', applianceRules),
  // Removes all objects matching the prefix from store
  RESET_FETCHED_BY_PREFIX: (state, prefix) => {
    const fetched = cloneDeep(state.fetched)
    findMFWTypesByPrefix(prefix).forEach(type => delete fetched[type])
    set(state, 'fetched', fetched)
  },

  SET_EDIT_OBJECT: (state, object) => {
    if (!object) return
    const index = state.editObjects.findIndex(obj => obj.Id === object.Id)
    if (index >= 0) {
      state.editObjects.splice(index, 1, object)
    } else {
      state.editObjects.push(object)
    }
  },

  SET_UNSYNCED_APPLIANCES: (state, count) => set(state, 'outOfSyncAppliances', count),

  SET_ANALYTICS: (state, data) => set(state, 'analytics', data),

  DELETE_ANALYTICS_ISSUE: (state, policyAnalyticsId) =>
    set(
      state,
      'analytics',
      state.analytics.filter(item => item.policyAnalyticsId !== policyAnalyticsId),
    ),

  SET_OBJECT_DIALOG: (state, value) => set(state, 'objectDialog', value),
  SET_CONFIGURATION_DIALOG: (state, value) => set(state, 'configurationDialog', value),
}

const actions = {
  /**
   * Fetches objects by a given `type` than stores them
   * @param {Object} { type, force } - type: the etm object Type as stored in mongo and force: to force fetch objects
   */
  async fetchObjectsByType({ state, commit }, { type, force = false }) {
    if ((state.fetched[type] && !force) || state.fetching[type]) return

    commit('SET_FETCHING', { objectType: type, value: true })
    const response = await api.cloud('Untangle_CommandCenter', 'GetPolicies', {
      type,
      paramOrder: 'type',
    })

    if (response.success && !response.message) {
      if (response.data?.length) {
        commit('UPDATE_OBJECTS', { objects: response.data })
      } else {
        commit('SET_OBJECTS_KEY', type)
      }
    } else {
      vuntangle.toast.add(i18n.t(response.message), 'error')
    }
    commit('SET_FETCHING', { objectType: type, value: false })
  },

  /**
   * Fetches objects by a given `preix` for `type` and than stores them
   * @param {Object} { prefix, force } - prefix: the prefix for etm object Type and force: to force fetch objects
   */
  async fetchObjectsByPrefix({ state, commit }, { prefix, force = false }) {
    // find all matching types against the prefix
    const matchedTypes = findMFWTypesByPrefix(prefix)
    // if data exists for all matched types then dont fetch again. if force = true then re fetch anyway
    const dataExists = !force && matchedTypes.every(type => state.fetched[type])
    // if data is being fetched atm for any of the objects then don't re fetch right now
    const fetching = matchedTypes.some(type => state.fetching[type])

    if (dataExists || fetching) return

    // set fetching = true for all matched types
    matchedTypes.forEach(type => commit('SET_FETCHING', { objectType: type, value: true }))
    const response = await api.cloud('Untangle_CommandCenter', 'GetPolicies', {
      type: prefix,
      includeJson: true,
      partialMatch: true,
      paramOrder: 'type includeJson partialMatch',
    })

    if (response.success && !response.message) {
      matchedTypes.forEach(type => {
        const objects = (response.data || []).filter(({ Type }) => Type === type)
        if (objects.length) {
          commit('UPDATE_OBJECTS', { objects })
        } else {
          commit('SET_OBJECTS_KEY', type)
        }
      })
    } else {
      vuntangle.toast.add(i18n.t(response.message), 'error')
    }
    // set fetching = false for all matched types
    matchedTypes.forEach(type => commit('SET_FETCHING', { objectType: type, value: false }))
  },

  /**
   * Fetches a single object by id and updates/adds it to the store
   * @param {number} policyId - id to be fetched/updated
   */
  async fetchObjectById({ commit }, policyId) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetPolicy', {
      policyId,
      paramOrder: 'policyId',
    })
    if (response.success && response.data) {
      commit('UPDATE_OBJECTS', { objects: [response.data], fetched: false })
    }

    return response.data
  },

  /**
   * Saves a single object in mongo
   * If object is created, upon success, the response will return the newly created guid
   * Then it will fetch that updated/created object to store its data
   */
  async saveObject({ dispatch, commit, state }, { object, autoFetch = true }) {
    /**
     * object with Id `create` is temporary used while creating a new policy
     * upon save just remove that Id so it gets created on the backend/mongo
     * */
    if (object.Id?.startsWith('create')) delete object.Id

    const action = object.Id ? 'UpdatePolicy' : 'CreatePolicy'
    const paramOrder = object.Id
      ? 'policyId name description type policyJson'
      : 'name description type version policyJson'
    const saveResponse = await api.cloud('Untangle_CommandCenter', action, {
      ...(object.Id && { policyId: object.Id }),
      name: object.Name,
      description: object.Description,
      type: object.Type,
      policyJson: JSON.stringify(object.PolicyJson),
      paramOrder,
    })

    if (saveResponse.success && saveResponse.data) {
      object.Id = object.Id || saveResponse.data

      // remove all policies associated with object, and re-fetch them
      const policies = object.Type === Type.Policy ? [object] : getPoliciesByDependencyId(object.Id)
      if (policies.length > 0) {
        commit('DELETE_POLICY_FROM_MAP', policies)
        dispatch('fetchDependencyMap', {
          policies,
          force: true,
        })
      }

      if (autoFetch) {
        if (saveResponse.data?.outOfSyncAppliances) {
          // if we got more unsynced appliances, call getAllApplianceAssignments so we update the firewalls grid
          if (state.outOfSyncAppliances !== saveResponse.data.outOfSyncAppliances) {
            dispatch('getAllApplianceAssignments', { force: true })
          }
          commit('SET_UNSYNCED_APPLIANCES', saveResponse.data.outOfSyncAppliances)
        }
        // upon success refetch the object to have an up-to-date version in the store
        return await dispatch('fetchObjectById', object.Id)
      }
      // if not refetching, then just return the response of the save call
      return saveResponse
    }
  },

  /**
   * Clone a policy, including all rules and conditions, then reset state
   *
   * @param dispatch
   * @param commit
   * @param policyId
   *
   * @return object|null
   */
  async clonePolicy({ dispatch, commit }, { policyId }) {
    const time = vuntangle.dates.formatLocaleDate(new Date(), true)
    const response = await api.cloud('Untangle_CommandCenter', 'ClonePolicy', {
      policyId,
      time,
      paramOrder: 'policyId time',
    })
    if (response.success && response.data.result) {
      // remove from store and refetch
      await dispatch('refreshPolicyDependencies', response.data.result.Id)
      // upon success commit the object to have an up-to-date version in the store
      await commit('UPDATE_OBJECTS', { objects: [response.data.result], fetched: false })
      return response.data.result
    }
    return null
  },

  /**
   * Imports a policy, including all rules and conditions, then reset state
   *
   * @param dispatch
   * @param commit
   * @param policyJson
   *
   * @return object|null
   */
  async importPolicy({ dispatch, commit }, { policyJson }) {
    const time = vuntangle.dates.formatLocaleDate(new Date(), true)
    const response = await api.cloud('Untangle_CommandCenter', 'ImportPolicy', {
      policyJson,
      time,
      paramOrder: 'policyJson time',
    })
    if (response.success && response.data.result) {
      // remove from store and refetch
      await dispatch('refreshPolicyDependencies', response.data.result.Id)
      // upon success commit the object to have an up-to-date version in the store
      await commit('UPDATE_OBJECTS', { objects: [response.data.result], fetched: false })
      return response.data.result
    }
    return null
  },

  /**
   * Clone an entire rule including its conditions
   *
   * @param dispatch
   * @param commit
   * @param type
   * @param id
   *
   * @returns object|null
   */
  async cloneRule({ dispatch, commit }, { type, id }) {
    const time = vuntangle.dates.formatLocaleDate(new Date(), true)
    const response = await api.cloud('Untangle_CommandCenter', 'CloneRule', {
      type,
      id,
      time,
      paramOrder: 'type id time',
    })
    if (response.success && response.data.result) {
      // remove from store and refetch
      await dispatch('refreshPolicyDependencies', response.data.result.Id)
      // upon success commit the object to have an up-to-date version in the store
      await commit('UPDATE_OBJECTS', { objects: [response.data.result], fetched: false })
      return response.data.result
    }
    return null
  },

  /**
   * Remove all rule and template from store and refetch conditions
   * @param dispatch
   * @param commit
   * @param policyId
   *
   * @return void
   */
  async refreshPolicyDependencies({ dispatch, commit }, { policyId }) {
    ;['mfw-rule', 'mfw-config'].forEach(prefix => commit('RESET_FETCHED_BY_PREFIX', prefix))
    await dispatch('fetchObjectsByType', { type: Type.ObjectCondition, force: true })
    // update dependency map with new policy
    await dispatch('fetchDependencyMap', { policies: [{ Id: policyId }], force: true })
  },

  /**
   * Removes given objects (policies or templates) from backend if unused and upon success from the store
   * Backend requires Id and Name for removal
   * @param { Array, Boolean } - objects and boolean to determine if the objects are of policy type
   */
  async deletePoliciesOrTemplatesIfUnused({ commit }, { objects, isPolicyOrTemplate = false }) {
    const list = objects.map(({ Id, Name, Type }) => {
      return { Id, Name, Type }
    })
    const endPoint = isPolicyOrTemplate ? 'DeletePoliciesOrTemplatesIfUnused' : 'DeleteObjectsIfUnused'
    const response = await api.cloud('Untangle_CommandCenter', endPoint, {
      objects: JSON.stringify(list),
      paramOrder: 'objects',
    })
    if (response.data?.dependencies) {
      // shows a info window with objects that cannot be deleted
      vuntangle.dialog.show({
        title: i18n.t('item_delete_fail'),
        component: DeleteDependencies,
        width: 600,
        componentProps: {
          dependencies: response.data.dependencies,
        },
        buttons: [
          {
            name: i18n.t('close'),
            handler() {
              this.onClose()
            },
          },
        ],
      })
    }
    // in case we can't delete because of dependencies we get an array back
    if (response.success && response.data.deleted === true) {
      commit('DELETE_OBJECTS', objects)
      commit(
        'DELETE_POLICY_FROM_MAP',
        objects.filter(o => o.Type === Type.Policy),
      )
    }
    return response
  },
  /**
   * Takes a list of polices and finds the objects they depend on
   *
   * @param policies list of policy objects. if empty; map all polices
   * @param force only update map if force is set, or the map hasn't been loaded
   */
  async fetchDependencyMap({ dispatch, state, commit }, { policies, force }) {
    if (!force && Object.keys(state.policyMap).length > 0) return

    // if no policies are passed, fetch the dependencies of all policies
    if (policies === undefined) {
      // fetch policies if none are loaded
      if (state.objects[Type.Policy] === undefined) await dispatch('fetchObjectsByType', { type: Type.Policy })
      policies = state.objects[Type.Policy]
    }
    // just pass policyIds
    const policyIds = (policies || []).map(({ Id }) => Id)
    if (policyIds.length === 0) return
    const response = await api.cloud('Untangle_CommandCenter', 'GetDependenciesOfPolicies', {
      policies: policyIds,
      paramOrder: 'policies',
    })

    if (response.success && response.data) {
      commit('UPDATE_POLICY_MAP', response.data)
    }
  },

  /**
   * Create a policy with default configuration
   * @param dispatch
   * @returns {Promise<*|{data}|{success}>}
   */
  async createDefaultPolicy({ dispatch }) {
    const response = await api.cloud('Untangle_CommandCenter', 'CreateDefaultPolicy')

    if (response.success && response.data) {
      const failedKeys = response.data.failedKeys
      if (failedKeys.length) {
        vuntangle.toast.add(i18n.t('missing_templates', { policyTypes: failedKeys.join(', ') }), 'error')
      }
      // remove from store and refetch
      await dispatch('refreshPolicyDependencies', response.data.policyId)
      // upon success refetch the object to have an up-to-date version in the store
      return await dispatch('fetchObjectById', response.data.policyId)
    }
  },

  /**
   * Get all appliance policy associations
   *
   * @returns
   */
  async getAllApplianceAssignments({ state, commit }, { force }) {
    if (!force && state.assignment?.length) return

    const response = await api.cloud('Untangle_CommandCenter', 'GetAllApplianceAssignments')
    if (response.success && !response.message) {
      commit('SET_ASSIGNMENT', response.data || [])
    } else {
      vuntangle.toast.add(i18n.t(response.message), 'error')
    }
  },

  /**
   * Get all policies associated to the appliance
   *
   * @returns
   */
  async getDataForAppliancePoliciesWidget({ commit }, uid) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetDataForAppliancePoliciesWidget', {
      uid,
      paramOrder: 'uid',
    })
    if (response.success && response.data) {
      commit('SET_APPLIANCE_POLICIES', response.data.policies)
      commit('SET_APPLIANCE_RULES', response.data.rules)
    }
  },

  /**
   * Update associations for list of applianceIds and assignments
   * @param {Object} { assignments , applianceIds }
   *
   * @return {Boolean}
   */
  async updateApplianceAssignments({}, { applianceIds, assignments }) {
    const response = await api.payload(
      {
        handler: 'Untangle_CommandCenter',
        paramOrder: 'payload',
        method: 'UpdateApplianceAssignments',
      },
      {
        assignments,
        applianceIds,
      },
    )

    return response.success || false
  },

  /**
   * Sync current policies on the specified appliances.
   *
   * @param {String[]} uids
   *
   * @return {Boolean}
   */
  async syncAppliancePolicies({}, uids) {
    const response = await api.cloud('Untangle_CommandCenter', 'SyncAppliancePolicies', {
      uids,
      paramOrder: 'uids',
    })

    return response.success || false
  },

  /**
   * Gets the count of policies which are out of sync and updates it in store
   */
  async getUnsyncedAppliancesCount({ commit }) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetUnsyncedAppliancesCount')

    if (response.success) {
      // upon success save the count in the store
      commit('SET_UNSYNCED_APPLIANCES', response.data)
    }
  },

  /**
   * Fetches policy analytics data
   *
   * @param {Boolean} runAnalytics
   */
  async fetchAnalytics({ commit }) {
    const response = await api.cloud('Untangle_CommandCenter', 'GetPolicyAnalytics')

    if (response.success) {
      // update store with received data
      commit('SET_ANALYTICS', response.data)
    }
  },

  /**
   * Removes given policy analytics issue from backend
   * @param { number } - policyAnalyticsId
   */
  async deletePolicyAnalyticsIssue({ commit }, { policyAnalyticsId }) {
    const response = await api.cloud('Untangle_CommandCenter', 'DeletePolicyAnalyticsIssue', {
      policyAnalyticsId,
      paramOrder: 'policyAnalyticsId',
    })
    const success = response.success && response.data
    if (success) {
      commit('DELETE_ANALYTICS_ISSUE', policyAnalyticsId)
    }
    return success
  },

  /**
   * Shows the edit object dialog
   * @param {Object} data - holding the object to be edited and the callback after creation { object: ..., cd: ...}
   */
  editObjectDialog({ commit }, data) {
    commit('SET_OBJECT_DIALOG', data)
  },

  /**
   * Just closes the edit object dialog by setting the props undefined
   */
  closeObjectDialog({ commit }) {
    commit('SET_OBJECT_DIALOG', { object: undefined, cb: undefined })
  },

  /**
   * Shows the edit configurations dialog
   * @param {Object} data - holding the object to be edited and the callback after creation { object: ..., cd: ...}
   */
  editConfigurationDialog({ commit }, data) {
    commit('SET_CONFIGURATION_DIALOG', data)
  },

  /**
   * Just closes the edit configuration dialog by setting the props undefined
   */
  closeConfigurationDialog({ commit }) {
    commit('SET_CONFIGURATION_DIALOG', { configuration: undefined, cb: undefined })
  },
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters,
  mutations,
  actions,
}

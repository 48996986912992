import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { dateTimeFormats, vuntangleEnLocale, vuntangleDeLocale, vuntangleJaLocale } from 'vuntangle'
import vuntangle from '@/plugins/vuntangle'
import en from '@/locales/en'
import de from '@/locales/de'
import ja from '@/locales/ja'
import api from '@/plugins/ut/ut-api'

Vue.use(VueI18n)

const LOCAL_ETM_HOST = 'local-launchpad.edge.arista.com'
const DEVELOP_ETM_HOST = 'develop-launchpad.edge.arista.com'

const isLocalEtm = () => {
  return window.location.host.includes(LOCAL_ETM_HOST)
}

const isDevelopEtm = () => {
  return window.location.host.includes(DEVELOP_ETM_HOST)
}

// Hot updates
if (module.hot) {
  module.hot.accept(['./../locales/en'], function () {
    i18n.setLocaleMessage('en', en)
    i18n.setLocaleMessage('de', de)
    i18n.setLocaleMessage('ja', ja)
  })
}

// extend the existing VueI18n class, so we can override the te method, to look in fallbackLocale as well
class CustomVueI18n extends VueI18n {
  _te(key, locale, messages, ...args) {
    const chain = this._getLocaleChain(locale, this.fallbackLocale)
    return chain.some(lang => super._te(key, lang, messages, ...args))
  }
}

const i18n = new CustomVueI18n({
  locale: 'en',
  fallbackLocale: {
    en: ['en', 'vuntangleEnLocale'],
    de: ['de', 'vuntangleDeLocale'],
    ja: ['ja', 'vuntangleJaLocale'],
  },
  messages: {
    en,
    de,
    ja,
    vuntangleEnLocale,
    vuntangleDeLocale,
    vuntangleJaLocale,
  },
  silentTranslationWarn: true,
  dateTimeFormats,
})

/**
 * i18n.availableLocales is an array of language codes: ['en', 'fr']
 * We want to able to show language selection with labels that describe the language rather than a code
 */
i18n.availableLocalesForUI = [
  {
    language: 'English (US)',
    code: 'en',
  },
  {
    language: 'Deutsch (DE)',
    code: 'de',
  },
  {
    language: '日本 (JA)',
    code: 'ja',
  },
]

/**
 * Set the current used locale (language) code
 */
i18n.setLocale = locale => {
  localStorage.setItem('i18n', locale)
  i18n.locale = locale
  vuntangle.locale = locale
}

// the translations debugging is enabled on local or develop hosts
if (isLocalEtm() || isDevelopEtm()) {
  /**
   * Logs info about missing or invalid translation strings in browser console
   * It highlights the missing/invalid keys surrounding them with `✗`
   * @param {string} locale - the current locale: en. de, ja
   * @param {string} key - the translation key
   */
  i18n.missing = (locale, key) => {
    // logs warning in case the key was already translated; equiv of $t($t('key'))
    if (key.includes(' ') || key.charAt(0) === key.charAt(0).toUpperCase()) {
      // eslint-disable-next-line no-console
      console.info(`i18n warn: "${key}" is not a valid translation key, it may be translated twice`)
    } else {
      // logs warning in case the key wasn't found at all
      // eslint-disable-next-line no-console
      console.info(`i18n warn: "${key}" key is not translated`)
      // call backend to save a log entry for this
      api.cloud('Untangle_CommandCenter', 'LogMissingTranslations', {
        key,
        paramOrder: 'key',
      })
    }

    return `✗${key}✗`
  }
}

i18n.setLocale(localStorage.getItem('i18n') || 'en')
export default i18n

// NOTE: using js module instead of JSON to be able to add comments

/**
 * EN - default localization
 * The rest of the locale files have to follow this as source
 * The localization keys are grouped based on function (e.g. buttons, fields, pages etc..)
 *
 *
 * Pluralization
 * Is achieved by defining locale using pipes
 * e.g.
 * english: network: "network | networks"
 * french: network: "réseau | réseaux"
 *
 * In template, is needed to use $tc() instead of $t()
 * $tc('network', 1) => "network"
 * $tc('network', 2) => "networks"
 */
export default {
  accept_link_label: 'Accept Link Label',
  access_event: 'Access rule block',
  account: 'My Account',
  account_balance: 'Account Balance',
  account_billed_info: 'Your account will be billed on the day that subscriptions renew',
  account_billing_cycle_info: 'Your billing cycle day for each month is',
  account_id: 'Account Id',
  account_management: 'Subscriptions, Billing, Invoices, User Management',
  account_no_address: 'Failed to redeem voucher. You have no address defined.',
  account_number: 'Account Number',
  account_owner: 'Account Owner: ',
  account_preferences_save_failed: 'Failed to update account preferences.',
  account_preferences_save_success:
    'Account preferences have been successfully updated. A refresh may be required to apply UI changes.',
  account_safety_only_two_factor_enabled:
    'For your account safety, this option is only enabled if your two-factor authentication is enabled',
  account_settings_av_save_failed: 'Authentication failed. Please check your credentials and try again.',
  account_settings_confirmation_required:
    'The requested account settings changes require additional confirmation. An email has been sent to the account owner.',
  account_settings_save_failed: 'Failed to update account settings.',
  account_settings_save_success: 'Account settings have been successfully updated.',
  account_type: 'Account Type',
  accounts_credentials_update_fail: 'Failed to update accounts credentials.',
  accounts_credentials_update_success: 'Accounts credentials successfully updated.',
  ach: 'ACH',
  ach_electronic_funds: 'ACH / Electronic Funds Transfer',
  ach_payment_error:
    'There was a problem processing your payment.  Please try re-entering it, or contact your bank directly.',
  active: 'Active',
  active_hosts: 'Active Hosts:',
  activity: 'Activity',
  ad_monitoring_agent: 'Active Directory Login Monitor Agent',
  ad_monitoring_summary:
    "The AD Login Monitor agent installs on an Active Directory server and monitors the server's login events and updates NGFW when a user logs in to a computer. <p>You can find more details regarding the agent in the <a target='_blank' href='https://wiki.untangle.com/index.php/Directory_Connector#Active_Directory_Server_Login_Monitor_Agent'>NG Firewall Wiki.</a>",
  add_account_failed: 'We could not send an invitation to the email you provided.',
  add_account_success: 'An invitation was successfully sent to the email address you provided',
  add_ach: 'Add ACH',
  add_ach_failure: 'Failed to add an ACH payment method.',
  add_ach_success: 'The ACH payment method was added successfully',
  add_alert_rule: 'Add Alert Rule',
  add_appliance: 'Add Appliance',
  add_appliance_error:
    'You do not have permission to add appliance to this organization, contact your organization manager to change permissions',
  add_appliance_to_account: 'Add Appliance To Account',
  add_appliance_totp: 'Add Appliance TOTP',
  add_appliances_failure: 'Failed to add the appliances to the network.',
  add_appliances_success: 'Successfully added the appliances to the network.',
  add_cc_failed: 'Unable to add credit card as entered',
  add_child_account: 'Add Account',
  add_credit_card: 'Add Credit Card',
  add_credit_card_confirm: 'The credit card has been added to your account successfully.',
  add_existing: 'Add Existing',
  add_existing_condition: 'Add existing condition ...',
  add_existing_rule: 'Add existing rule ...',
  add_header: 'Add Header',
  add_license: 'Add License',
  add_notification_profile: 'Add Notification Profile',
  add_policy_to_appliance: 'Add policy to the appliance',
  add_selected_appliances: 'Add selected appliances to this network',
  add_template: 'Add Template',
  add_to_policy: 'Add to Policy',
  add_user: 'Add User',
  add_users: 'Add Users',
  add_wan_rule: 'Add WAN Rule',
  additional_information: 'Additional information',
  address_line2: 'Address Line 2',
  address_not_verified:
    'We could not verify the address for this payment method. Please review your address information and contact Arista Edge Threat Management Sales for assistance.',
  advertising: 'Advertising & Marketing',
  agni_user: 'AGNI User',
  agni_user_groups: 'AGNI User Groups',
  agree_and_continue: 'Agree and Continue',
  agreement: "I agree to Arista Edge Threat Management's {0}",
  agriculture: 'Agriculture',
  alert_create_failure: 'Failed to create the alert rule.',
  alert_create_success: 'Successfully created the new alert rule. The changes may take up to 20 minutes to propagate.',
  alert_edit_failure: 'Failed to edit the alert rule.',
  alert_edit_success: 'Successfully edited the alert rule. The changes may take up to 20 minutes to propagate.',
  alert_history: 'Alert History',
  alert_login_failed: 'Failed login attempt',
  alert_login_success: 'Successful login attempt',
  alert_new_device_discovered: 'New device found by Network Discovery',
  alert_rule: 'Alert Rule',
  alert_rule_hint: 'String that will trigger this alert if found in event summary message',
  alert_rule_name_hint: 'Unique alert rule name',
  alert_rules: 'Alert Rules',
  alerts: 'Alerts',
  alerts_received: 'Alerts Received',
  all_appliances: 'All Appliances',
  all_current_and_future_appliances: 'All Current and <b>Future</b> Appliances',
  all_offline: 'ALL APPLIANCES OFFLINE',
  all_online: 'ALL APPLIANCES ONLINE',
  all_traffic_local: 'All traffic from local networks',
  analyze: 'Analyze',
  antivirus_scan: 'Antivirus Scan',
  api_key: 'API Key',
  api_token: 'API Token',
  appliance: 'Appliance',
  appliance_action_not_found: 'Appliance action not found',
  appliance_add_licenses_sub_not_assignable: "This license can't be assigned to an appliance",
  appliance_add_totp_message: 'Add Time-Based One-Time Password authentication to the appliance',
  appliance_backup: 'Appliance Backup',
  appliance_count: 'no appliances | one appliance | {n} appliances',
  appliance_details: 'Appliance Details',
  appliance_does_not_exist: 'Appliance does not exist!',
  appliance_incompatible_with_vpn:
    'App(s) {applist} are enabled.  Disable these apps to configure this appliance for Software Defined Networks',
  appliance_incompatible_with_vpn_title: 'This appliance is not compatible with WireGuard VPN',
  appliance_interface_disabled: 'Interface will be disabled',
  appliance_invalid_search: 'Invalid search item value.',
  appliance_license_will_auto_renew: 'This subscription will auto-renew in {days} days',
  appliance_licenses: 'Appliance Licenses',
  appliance_licenses_days_remaining: '{days} days remaining',
  appliance_lose_connectivity: 'This may cause the appliance to briefly lose connectivity',
  appliance_map: 'Appliance Map',
  appliance_model: 'Appliance Model',
  appliance_must_be_online: 'The appliance must be connected to ETM to set its TOTP settings.',
  appliance_not_connected_cmd:
    'The appliance could not connect to {to}. Check that the appliance is online, with Internet access. Check that the UID ({uid}) or serial number for the appliance is correct.',
  appliance_offline_totp_message:
    "Add or remove Time-Based One-Time Password authentication to the appliance when it's online",
  appliance_policies: 'Appliance Policies',
  appliance_remote_access_failed: 'Failed to create a remote connection to the appliance',
  appliance_remove_totp_message: 'This appliance already has Time-Based One-Time Password set',
  appliance_search_updates: 'Checking for Software Updates',
  appliance_set_label_message: 'Please enter a label for your appliance',
  appliance_set_wifi_region_message: 'Please select a Wi-Fi region for your appliance',
  appliance_settings_update_initiated: 'A task has been queued to update Appliance settings.',
  appliance_status_invalid_license_settings: 'Invalid license settings',
  appliance_status_license_expired: 'One or more subscriptions expired!',
  appliance_status_license_expiring_soon: 'One or more subscriptions expiring soon',
  appliance_status_no_licenses: 'No active licenses found',
  appliance_status_nolicense_command_center:
    'This appliance must be upgraded to a Complete license for full ETM Dashboard functionality',
  appliance_status_offline: 'OFFLINE',
  appliance_status_online: 'ONLINE',
  appliance_status_seat_count_exceeded: 'License seat count exceeded! Not all hosts are protected!',
  appliance_status_trial_expired: 'One or more trials expired!',
  appliance_status_trial_expiring_soon: 'One or more trials expiring soon',
  appliance_to_restore: 'Appliance(s) to Restore',
  appliance_update_software_failed_title: 'Update Software Failed',
  appliance_upgrade_available: '[Upgrade Available]',
  appliance_version_incompatible_with_vpn:
    'Incompatible appliance version - appliance must have at least version 16.0 (for NGFW) and 3.0 (for Micro Edge)',
  appliance_widgets: 'Appliance Widgets',
  appliance_widgets_text:
    'You can select the widgets you want to be displayed. Use drag & drop to specify the order you want them to be shown.',
  appliance_without_a_policy_assigned: 'Appliance without a Policy Assigned',
  appliances: 'Appliances',
  appliances_map: 'Appliances Map',
  appliances_out_of_sync: '{0} appliances out of sync',
  appliances_selected: 'one appliance selected | {n} appliances selected',
  appliances_title: 'Appliances',
  appliances_update: 'Appliances Software Update',
  appliances2: 'Appliances:',
  application_cat: 'Application Category',
  application_control_create_fail: 'Failed to create Application Control.',
  application_control_create_success: 'Application Control successfully created.',
  application_control_delete_fail: 'Failed to delete Application Control.',
  application_control_delete_success: 'Application Control successfully deleted.',
  application_control_description:
    'Use an Application Control template to define which applications to permit or deny by this policy',
  application_control_list_group: 'Application Control List Groups',
  application_control_update_fail: 'Failed to update Application Control.',
  application_control_update_success: 'Application Control successfully updated.',
  application_groups: 'Application Groups',
  application_productivity: 'Application Productivity Level',
  application_risk: 'Application Risk Level',
  applicationcontrol_session_blocked: 'Session blocked by Application Control',
  applicationcontrol_session_flagged: 'Session flagged by Application Control',
  applicationcontrol_session_rejected: 'Session rejected by Application Control',
  applications_control_list: 'Application Control Lists',
  architecture: 'Architecture',
  arista_authorized: 'Arista Authorized',
  arista_edge_threat_management: 'Arista Edge Threat Management',
  arista_q_series: 'Arista Q Series Appliance',
  assign_a_policy: 'Assign a policy',
  assign_appliance_duplicate_failed: 'Unable to add this appliance. The appliance already assigned to this account.',
  assign_appliance_organization_failed:
    'Unable to add this appliance. If it already belongs to another organization, it must be removed from its current organization before it can be added to this one.',
  assign_label: 'Assign a label to your appliance',
  assign_sub_failed: 'Failed to assign the subscription to the appliance.',
  assign_sub_success: 'The subscription has been successfully assigned.',
  assign_sub_to_appliance: 'Assign subscription to an appliance',
  assign_subscription: 'Assign Subscription',
  assign_subscription_to_this_appliance: 'Assign a subscription to this appliance',
  assigned_to: 'Assigned To',
  assignment_description: 'Assign and synchronize policies to one or more security appliances',
  assignment_status: 'Assignment Status',
  audit_history: 'Audit History',
  audit_info: 'Audit Info',
  authorization_endpoint_url: 'Authorization Endpoint URL',
  authorized_reseller: 'Authorized Reseller',
  auto_assign_subscriptions: 'Auto-Assign Subscriptions',
  auto_assign_subscriptions_note:
    'Note: This will assign any un-assigned subscription to the new appliances added to the account',
  auto_renew: 'Auto Renew',
  auto_renew_failed: 'Auto renewal failed for subscription(s) ',
  auto_renew_toggled: 'Auto renewal has been updated for subscription ',
  automatic_config:
    'The VPN Tunnel endpoint will be configured using the Internet IP address registered to ETM Dashboard.',
  automatic_login: 'Automatic Login',
  automotive: 'Automotive',
  available_physical_memory: 'Available Physical Memory',
  available_virtual_memory: 'Available Virtual Memory',
  available_zones: 'Available Zones',
  average_jitter: 'Average Jitter',
  average_latency: 'Average Latency',
  average_packet_loss: 'Average Packet Loss',
  back: 'Back',
  back_to_policies: 'Back to Policies',
  backup_appliance_settings: 'Backup Appliance Settings',
  backup_date: 'Backup Date: ',
  backup_download_failed: 'The backup file failed to be downloaded.',
  backup_generation_failure: 'Failed to generate a backup of the master appliance.',
  backup_hostname: 'Hostname: ',
  backup_label: 'Label: ',
  backup_no_license: 'Failed to generate backup. Appliance does not have a complete License',
  backup_not_generated: 'The backup failed to be generated.',
  backup_uid: 'UID: ',
  backups: 'Backups',
  bad_credit_card_exp_info: 'The credit card is expired.  Please re-enter',
  bad_credit_card_num_info: 'The credit card number you have entered is not valid.  Please re-enter',
  band_tier: 'Band/Tier',
  bandwidth_control: 'Bandwidth Control',
  bank_name: 'Bank Name',
  bank_routing_number: 'Bank Routing Number',
  banking_and_financial: 'Banking & Financial',
  bcc_email_addresses: 'CSV list of BCC email addresses',
  beta: 'beta',
  billing: 'Billing',
  billing_address: 'Billing Address',
  billing_agreement_id: 'Billing Agreement ID: ',
  billing_email: 'Billing Email',
  billing_email_info:
    'Receives the following: <br />' +
    '* Invoices <br/>' +
    '* Failed payment notifications <br/>' +
    '* Notifications of expiring credit cards <br/>' +
    '* 30 day renewal notifications',
  billing_info: 'Billing Info',
  billing_info_account_positive_balance:
    '<b>Note: </b>You currently have an account balance of: {val}.<br/>By updating your billing information, any outstanding balance will be collected.<br/>',
  billing_info_credit_positive_balance:
    '<b>Note: </b> You currently have a credit balance of: {val}.<br/>These amounts represent credits that will be deducted from your next payment.<br/>',
  billing_total: '12 Month Billing Total',
  billing_trend: '12 Month Billing Trend',
  bitdefender_access_url: 'Access Url',
  bitdefender_credentials: 'Bitdefender Endpoint Security',
  bitdefender_credentials_instructions:
    'Please enter the API key you provisioned in your <a href="https://cloud.gravityzone.bitdefender.com/" target="_blank">Bitdefender GravityZone</a> account. This will augment the \'Hosts\' section with additional information and functionality. You can read more about it <a href="https://support.edge.arista.com/hc/en-us/articles/360051479273" target="_blank">here</a>',
  blocked: 'Is Blocked',
  blocked_threats: 'Blocked Threats',
  branding_manager: 'Branding Manager',
  business: 'Business',
  buy: 'Buy',
  buy_license: 'Buy License',
  buy_subscriptions: 'Buy Subscriptions',
  bypassed: 'Is Bypassed',
  callback_url: 'Callback URL',
  cannot_delete_account_because_haas: 'Cannot delete account because there are active HaaS subscriptions',
  cannot_leave_only_org: 'Cannot leave your only organization',
  cannot_leave_org_error: 'Failed to leave organization',
  cannot_leave_primary_org: 'Cannot leave your primary organization',
  captive_portal_description:
    'Use a Captive Portal template to define when to apply Captive Portal to devices on the network',
  captive_portal_user: 'Captive Portal User',
  captiveportal_create_fail: 'Failed to create Captive Portal.',
  captiveportal_create_success: 'Captive Portal successfully created.',
  captiveportal_session_blocked: 'Session blocked by Captive Portal',
  captiveportal_session_redirected: 'Session redirected by Captive Portal',
  captiveportal_update_fail: 'Failed to update Captive Portal.',
  captiveportal_update_success: 'Captive Portal successfully updated.',
  card_expiration_not_verified:
    'We could not verify your card expiration date. Please try re-entering it, or contact your credit card provider directly.',
  card_number: 'Card Number',
  card_type: 'Card Type',
  cardholder_name: 'Cardholder Name',
  cc_email_addresses: 'CSV list of CC email addresses',
  cc_expired_update_in_billing_info:
    'Your credit card has expired. Please take a moment to update it by going to Account -> My Account -> Billing Info.',
  cert: 'Cert',
  certificate_id: 'Certificate ID',
  change_password: 'Change Password',
  change_policy_first_packet: 'Change the policy condition to a condition that can be evaluated on the first packet',
  changed: 'Changed',
  charged_payment_method: 'Payment method that will be charged: ',
  checking: 'Checking',
  checksum: 'Checksum',
  choose_endpoint: 'Automatic ({IP})',
  choose_global_configuration: 'Choose global configuration',
  city: 'City',
  class: 'Class',
  class_type: 'Class Type',
  clear_default: 'Clear Default',
  client_authentication_method: 'Client Authentication Method',
  client_endpoint_link: '&nbsp;&nbsp;&nbsp;Go to my {vendorname} console',
  client_geoip: 'Client Geo-IP',
  client_id: 'Client Id',
  client_secret: 'Client Secret',
  cloud_backups: 'Cloud Backups',
  cloud_backups_confirmation:
    'Are you sure you want to restore this appliance with the selected backup? The appliance will use the configuration and policies from the backup with the exception of network settings.<br/><br/>A toast message containing the result of the restoration will appear on your screen once the process has finished.',
  cloud_backups_confirmation_title: 'Confirm Backup Restoration',
  cloud_backups_create_backup_error: 'Failed creating the backup. Please try again later.',
  cloud_backups_create_backup_success: 'Backup created successfully!',
  cloud_backups_create_confirmation: 'Are you sure you would like to create a backup from the selected appliance?',
  cloud_vision: 'Cloud Vision',
  command_executed_successfully: 'Command Executed Successfully',
  command_failed: 'Failed to execute requested command.',
  command_failure: 'Unable to contact appliance',
  command_sent_successfully: 'Command enqueued successfully.',
  company: 'Company',
  company_description: 'Company Description',
  company_information: 'Company Information',
  company_name: 'Company Name',
  company_website: 'Company Website',
  compare: 'Compare',
  compare_description:
    'On the left side are the settings currently set on the appliance. On the right side, are the settings that will be pushed to the appliance on the next sync.',
  compare_no_data: 'Unable to fetch data for comparison',
  comparison: 'Comparison',
  complete:
    'Appliance successfully added. If you requested hostname change or template push, this has been scheduled and will complete soon.',
  complete_appliance_setup: 'Complete Appliance Setup',
  complete_remote:
    'Appliance successfully added.  If you requested hostname change or template push, this has been scheduled and will complete soon.  A new window will now be opened accessing this appliance so you can continue setup',
  completed: 'Completed',
  condition_description: 'Condition Description',
  condition_in_use:
    'This condition is assigned to multiple policies. Consider making a copy or any change you make will apply to following policies: {0}',
  condition_name: 'Condition Name',
  condition_object_already_added: 'Condition object already in list',
  conditions_description:
    'Use conditions to define when to apply this policy. No conditions means the policy applies to all traffic flows',
  config_failed:
    'Unable to connect to this appliance.  Your configuration choices will be pushed as soon as the appliances is connected and available',
  config_incomplete:
    'Configuration did not complete successfully for this appliance.  {errorMessage}.  You may need to re-run some steps on the appliance',
  configuration_json: 'Configuration JSON',
  configuration_templates: 'Configuration Templates',
  configure_appliance: 'Configure Appliance',
  confirm_delete_child_dialog:
    'If you delete this account, all subscriptions shared with it will be unshared, and you will be removed as an admin from the account. Are you sure you want to continue?',
  confirm_delete_data: 'Are you sure you want to delete selected item(s)?',
  confirm_delete_network: 'Are you sure you want to delete the selected network?',
  confirm_delete_policy: 'Are you sure you want to delete the selected policies?',
  confirm_delete_templates: 'Are you sure you want to delete the selected templates?',
  confirm_delete_wan_policy: 'Deleting selected policies will also delete the following WAN Rules.',
  confirm_delete_wan_rules: 'Are you sure you want to delete the selected WAN rules?',
  confirm_generate_vouchers: 'Your account will be charged and you will receive voucher(s) for ',
  confirm_generate_vouchers_2: 'Are you sure you want to generate these vouchers? ',
  confirm_leave_org: 'Are you sure you want to leave this organization?',
  confirm_notification_profile_delete: 'Are you sure you want to delete the selected notification profiles?',
  confirm_proceed: 'Are you sure you want to proceed?',
  confirm_remove_appliances: 'Are you sure you want to remove the selected appliances?',
  confirm_remove_task: 'Are you sure you want to remove the selected tasks?',
  confirm_remove_users: 'Are you sure you want to remove the selected users from your account?',
  confirm_reset_vpn_tunnels: 'Are you sure you want to remove and disable all VPN tunnels?',
  confirm_reset_wan_rules:
    'Are you sure you want to remove all ETM Dashboard WAN Rules from the network and all devices?',
  confirm_restore: 'Please Confirm the Following Restore',
  confirm_restore_button: 'Confirm Restore',
  conflicting_subnets:
    "Subnets shown in red are already configured on another appliance in your network, so this appliance can't be VPN enabled",
  connect: 'Connect',
  construction: 'Construction',
  contact: 'Contact',
  contact_information: 'Contact Information',
  could_not_authenticate: 'Could not authenticate!',
  could_not_find_org: 'Could not locate the organization you wish to leave',
  countries: 'Countries',
  coupons: 'Coupons',
  cpu_count: 'CPU Count',
  create: 'Create',
  create_account: 'Create Account',
  create_account_msg_other: 'Please fill other account information',
  create_account_msg_required: 'Please fill login information',
  create_account_validate_email_and_token_error:
    'Your activation token or email is not valid. Please check your email account again and request a new invite if issues persist.',
  create_alert_rule: 'Create Alert Rule',
  create_backup: 'Create Backup',
  create_condition: 'Create Condition',
  create_configuration: 'Create Configuration',
  create_default: 'Create Default',
  create_default_policy_confirmation: 'Are you sure you want to create a default policy?',
  create_defaults: 'Create Defaults',
  create_filter_group: 'Create filter rules group',
  create_group: 'Create Group',
  create_network: 'Create Network',
  create_new: 'Create New',
  create_notification_profile: 'Create Notification Profile',
  create_notification_profile_failed: 'Create Notification Profile Failed',
  create_object: 'Create Object',
  create_paypal_agreement: 'Create PayPal Billing Agreement',
  create_paypal_billing_agreement_failed:
    'There was a problem creating a Billing Agreement - please contact Arista Edge Threat Management Sales for more information.',
  create_paypal_billing_agreement_redirect_failed:
    'There was a problem setting up a connection with PayPal - please contact Arista Edge Threat Management Sales for more information.',
  create_paypal_billing_agreement_success: 'PayPal Billing Agreement was successfully created',
  create_policy: 'Create Policy',
  create_rule: 'Create Rule',
  create_template: 'Create Template',
  create_template_instructions:
    'This operation will create a template for updating the Arista Edge Threat Management ' +
    'appliances in the target appliance list with the configurations and ' +
    'policies from the template master (with the exception of network ' +
    'configuration).',
  create_update_wan_rule: 'Creating/Updating WAN Rule',
  created: 'Created',
  creating_network: 'Creating Network',
  creating_updating_alert: 'Creating/Updating Alert Rule',
  creating_updating_notification: 'Creating/Updating Notification Profile',
  credit_card: 'Credit Card',
  credit_cards: 'Credit Cards',
  criteria: 'Criteria',
  ctw_example: 'CTW followed by 8 digits',
  current: 'Current Organization',
  current_password: 'Current Password',
  current_product: 'Current Product',
  current_server_datetime: 'Current Appliance Date/Time',
  current_subscriptions: 'Current Subscriptions',
  custom_interface_zone: 'Custom Interface zone',
  customer_accounts: 'Customer Accounts',
  customer_not_found: 'A user with the specified email address was not found.',
  daily: 'Daily',
  dashboard_widgets_text:
    'You can select the widgets you want to be displayed. Use drag & drop to specify the order you want them to be shown.',
  data: 'Data',
  database_schema_mismatch: 'Database schema mismatch.',
  database_status_offline: 'Database status offline.',
  database_status_online: 'Database status online.',
  database_write_failed: 'Failed to write into database.',
  databases_create_fail: 'Failed to create Database.',
  databases_create_success: 'Database successfully created.',
  databases_delete_fail: 'Failed to delete Database.',
  databases_delete_success: 'Database successfully deleted.',
  databases_update_fail: 'Failed to update Database.',
  databases_update_success: 'Database successfully updated.',
  date: 'Date',
  date_created: 'Date Created',
  date_installed: 'Date Installed',
  date_redeemed: 'Date Redeemed',
  date_time: 'Date/Time',
  date_updated: 'Date Updated',
  day_of_the_week: 'Day of the Week',
  default_payment_method: 'Default Payment Method',
  default_payment_update: 'Your default payment method was updated successfully',
  default_payment_update_failed: 'Your default payment method could not be saved',
  default_rule: 'Default rule',
  delayed_complete_no_config:
    'Appliance successfully added.  Tasks have been queued to finish setup on your appliance and make any other configuration changes requested',
  delete: 'Delete',
  delete_account: 'Delete Account',
  delete_account_confirmation:
    "<strong>WARNING!</strong> Deleting the account will delete all data associated with your account and log you out. <strong>This operation CANNOT be undone!</strong> <br/><br/>Please type the word 'DELETE' below. <br/>",
  delete_account_failed: 'There was an error deleting the account',
  delete_account_success: 'The account was successfully removed',
  delete_account_warning:
    'WARNING! Deleting the account will delete all data associated with your account and log you out. This operation cannot be undone!',
  delete_ach_failed: 'At least one ACH must be on file, or another default payment method must be selected.',
  delete_ach_success: 'The ACH payment method you selected was deleted successfully',
  delete_alert_rule: 'Delete Alert Rules',
  delete_alert_rules_confirm: 'Are you sure you want to delete the selected alert rules?',
  delete_alert_rules_failed: 'Failed to delete the selected alert rules.',
  delete_alert_rules_success: 'Successfully deleted the selected alert rules.',
  delete_av_confirm_text: 'Are you sure you want to remove anti-virus settings from your account?',
  delete_av_confirm_title: 'Delete Anti-Virus settings',
  delete_cc_confirm_text: 'Are you sure you want to remove selected credit card from your account?',
  delete_cc_confirm_title: 'Delete Selected Credit Card',
  delete_cc_failed: 'At least one credit card must be on file, or another default payment method must be selected.',
  delete_child_dialog: 'Delete Account',
  delete_confirm: 'Are you sure you want to remove selected appliances from your account?',
  delete_confirm_network_also:
    'Are you sure you want to remove selected appliance from your account? Note this is the last appliance on the Network {networkName}, so this network will also be removed.',
  delete_credit_card_confirm: 'The credit card you selected was deleted successfully.',
  delete_duplicate_condition: 'Delete duplicate condition',
  delete_duplicate_group: 'Delete duplicate group',
  delete_duplicate_object: 'Delete duplicate object',
  delete_duplicate_rule: 'Delete duplicate rule',
  delete_failure: 'Failed to delete selected {0}',
  delete_network: 'Delete Network',
  delete_notification_profile: 'Delete Notification Profile',
  delete_notification_profile_error: 'We could not delete the selected notification profiles.',
  delete_notification_profile_failed: 'Delete Notification Profile Failed',
  delete_notification_profile_success: 'Successfully deleted the selected notification profiles.',
  delete_notification_profiles: 'Delete Notification Profiles',
  delete_notification_profiles_error: 'Error deleting notification profiles',
  delete_notification_profiles_error_associated_to_alert_rules:
    'Following notification profiles cannot be deleted because they are attached to alert rules:',
  delete_policies: 'Delete Policies',
  delete_policy: 'Delete Policy',
  delete_success: 'Selected {0} were deleted successfully',
  delete_templates: 'Delete Templates',
  delete_title: 'Delete Appliance',
  delete_voucher_fail_not_owner: 'Error, you cannot delete vouchers you are not the owner of',
  delete_vouchers: 'Delete Vouchers',
  delete_vouchers_failure: 'The selected vouchers could not be deleted.',
  delete_vouchers_message: 'Are you sure you want to delete the selected vouchers?',
  delete_vouchers_success: 'Successfully deleted the selected vouchers.',
  delete_vpn_failed: 'Failed to delete VPN tunnels',
  delete_vpn_tunnels: 'Delete VPN Tunnels',
  delete_wan_rules: 'Delete WAN Rules',
  deleted: 'Deleted',
  deleting_ach_payment: 'Deleting ACH Payment',
  deleting_credit_card: 'Deleting credit card',
  denial_of_service_create_fail: 'Failed to create Denial of Service.',
  denial_of_service_create_success: 'Denial of Service successfully created.',
  denial_of_service_delete_fail: 'Failed to delete Denial of Service.',
  denial_of_service_delete_success: 'Denial of Service successfully deleted.',
  denial_of_service_update_fail: 'Failed to update Denial of Service.',
  denial_of_service_update_success: 'Denial of Service successfully updated.',
  destination_dns_hint: 'Destination DNS Hint',
  details: 'Details',
  detected_intrusions: 'Intrusions Detected',
  dev: 'dev',
  device_id: 'Device Id',
  dhcp_create_fail: 'Failed to create DHCP.',
  dhcp_create_success: 'DHCP successfully created.',
  dhcp_update_fail: 'Failed to update DHCP.',
  dhcp_update_success: 'DHCP successfully updated.',
  directory_connector: 'Directory Connector',
  disagree: 'Disagree',
  discard: 'Discard',
  disconnected_appliance_expired_certificate:
    'Your appliance may show as disconnected due to expired certificate. Please update to the most recent version to restore ETM Dashboard connectivity.',
  disconnected_warning1: 'The appliance specified is not currently connected to our system.',
  disconnected_warning2:
    'The following screen allows you to define configuration information for your appliance.<br>We will attempt to connect to it and update it with this information for 7 days.',
  disconnected_warning3:
    'After that time, you will need to connect to this appliance and update it manually, when it is available',
  discount: 'Discount',
  discovery_create_fail: 'Failed to create Network Discovery.',
  discovery_create_success: 'Network Discovery successfully created.',
  discovery_update_fail: 'Failed to update Network Discovery.',
  discovery_update_success: 'Network Discovery successfully updated.',
  disk: 'Disk',
  disk_information: 'Disk Information',
  dns_create_fail: 'Failed to create DNS.',
  dns_create_success: 'DNS successfully created.',
  dns_hint: 'DNS Hint',
  dns_update_fail: 'Failed to update DNS.',
  dns_update_success: 'DNS successfully updated.',
  docker_container: 'Docker Container',
  domain: 'Domain',
  domain_field: 'Please enter your domain',
  download_saml: 'Download SAML',
  download_sp_metadata: 'Download SP Metadata',
  duplicate_condition: 'Duplicate condition of',
  duplicate_group: 'Duplicate group of',
  duplicate_object: 'Duplicate object of',
  duplicate_reboot_time: 'This time has already been scheduled for a reboot',
  duplicate_rule: 'Duplicate rule of',
  duplicate_transaction:
    'There was a duplicate transaction detected. Please check with your payment provider or contact Arista Edge Threat Management Sales for assistance.',
  dynamic_lists_update_fail: 'Failed to update Dynamic Lists.',
  dynamic_lists_update_success: 'Dynamic Lists successfully updated.',
  dynamiclists_session_blocked: 'Session blocked by Dynamic Lists',
  edit_alert_rule: 'Edit Alert Rule',
  edit_condition: 'Edit Condition',
  edit_conditions: 'Edit conditions',
  edit_configuration: 'Edit Configuration',
  edit_credit_card: 'Edit Credit Card',
  edit_email_template: 'Edit Email Template',
  edit_email_template_fail: 'There was an error saving the email template',
  edit_email_template_success: 'Email template saved successfully',
  edit_group: 'Edit Group',
  edit_location: 'Edit Location',
  edit_location_text: 'Enter an address for your appliance',
  edit_notification_profile: 'Edit Notification Profile',
  edit_policy_order: 'Edit policy Order',
  edit_shared_subnets_message:
    'Select subnets behind this appliance that must be accessible to all other peered networks in this VPN. An empty selection means the appliance will be in client mode.',
  edit_shared_subnets_message_ngfw:
    'Select subnets behind this appliance that must be accessible to all other peered networks in this VPN. At least one enabled subnet is required as client mode is not currently supported for NGFW',
  edit_template: 'Edit Template',
  edit_user_permissions: 'Edit User Permissions',
  edit_user_permissions_fail: "Unable to update user's permissions.",
  edit_user_permissions_success: 'User permissions have been successfully updated.',
  edit_wan_rule: 'Edit WAN Rule',
  education: 'Education',
  electronic_record: 'Electronic Record',
  electronic_record_button: 'Request Copy',
  electronic_record_text: 'Request a copy of your electronic record',
  email: 'Email',
  email_body: 'Body',
  email_configuration: 'Email Configuration',
  email_contact: 'Email Contact',
  email_from: 'From',
  email_hint: 'Enter comma separated email addresses for users you would like to invite',
  email_in_use: 'The main email address that you entered is already in use.',
  email_not_found: 'Email address not found.',
  email_send_failed: 'Failed to send password reset email.',
  email_subject: 'Subject',
  email_to: 'To',
  email_validation: 'This field must be a valid email',
  enable_auto_login: 'Enable Automatic Login for Remote Access',
  enable_mfa: 'Enable Two-Factor Authentication',
  enable_mfa_description:
    'Two-factor authentication provides better security for your account. After logging in with your email and password, you will also be asked to enter a verification code. <a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360006054634">Learn more</a> about setting 2FA to secure your account',
  enable_sso: 'Enable SSO',
  enable_vpn_message: 'Enabling VPN Access will add tunnels with all VPN enabled appliances in the current network.',
  encryption_certificate: 'Encryption Certificate',
  end_date: 'End Date',
  end_time: 'End Time',
  endpoint_address_override: 'Endpoint Address Override',
  endpoint_id: 'Endpoint Id',
  endpoint_security: 'Endpoint Security',
  endpoint_security_title: 'What Endpoint Security System are you using?',
  endpoint_service_info_1: 'Input one or more protocol/port combination that represents a "Service" object.',
  endpoint_service_info_2:
    'When the object is used in a Service condition, the condition is fulfilled if any of the below combinations is fulfilled.',
  endpoint_url: 'Endpoint URL',
  engine_version: 'Engine Version',
  enter_md5_hash_ip_url_mac:
    'Enter an IP address, MAC address, URL, or file MD5 hash to retrieve detailed information about the item.',
  enter_purchase_order_number: 'Please enter a new Purchase Order Number for this subscription',
  enter_uid_or_serial_to_add:
    'Enter the UID or serial number of an appliance. &nbsp;<a href="{url}" target="_blank">How do I find my UID?</a>',
  entertainment: 'Entertainment',
  entitled: 'Is Entitled',
  entity_id: 'Entity Id',
  error_message: 'Error Message',
  error_occurred: 'An error has occurred: ',
  etm_dashboard: 'ETM Dashboard',
  event_log: 'Event Log',
  exact_14: 'Exact 14 alphanumeric characters',
  existing_user_login: 'Cannot create a new account for this email address.',
  exit: 'Exit',
  expiration_date: 'Expiration Date',
  expiration_month: 'Expiration Month',
  expiration_year: 'Expiration Year',
  expired_reset_key: 'Your reset key is not valid or has expired. Please reset your password again.',
  expires: 'Expires',
  export_settings_failed: 'Failed export settings attempt',
  failed_completing_invite: 'We are unable to complete the invitation at this time',
  failed_push: 'Failed to push the policy {policyName} to the selected appliances.',
  failed_redeem_no_zuora: 'Failed to redeem voucher. You have no payment method defined.',
  failed_save_user_settings: 'Failed to save user settings',
  fax: 'Fax',
  feature_not_supported: '{0} feature not supported',
  feature_upgrade_message:
    'The appliance does not support this feature. Please upgrade appliance to benefit of all latest features.',
  federal_government: 'Federal Government',
  file_type: 'File Type',
  filesize: 'File Size',
  filter_event: 'Filter rule block',
  filter_group_description: 'Add filter rules to group',
  final_step: 'Final Step',
  finance: 'Finance & Insurance',
  firewall_filter_create_fail: 'Failed to create Filter rules.',
  firewall_filter_create_success: 'Filter rules successfully created.',
  firewall_filter_update_fail: 'Failed to update Filter rules.',
  firewall_filter_update_success: 'Filter rules successfully updated.',
  firewall_nat_create_fail: 'Failed to create NAT rules.',
  firewall_nat_create_success: 'NAT rules successfully created.',
  firewall_nat_update_fail: 'Failed to update NAT rules.',
  firewall_nat_update_success: 'NAT rules successfully updated.',
  firewall_port_forward_create_fail: 'Failed to create Port forward rules.',
  firewall_port_forward_create_success: 'Port forward rules successfully created.',
  firewall_port_forward_update_fail: 'Failed to update Port forward rules.',
  firewall_port_forward_update_success: 'Port forward rules successfully updated.',
  firewall_shaping_create_fail: 'Failed to create Shaping rules.',
  firewall_shaping_create_success: 'Shaping rules successfully created.',
  firewall_shaping_update_fail: 'Failed to update Shaping rules.',
  firewall_shaping_update_success: 'Shaping rules successfully updated.',
  firewalls: 'Firewalls',
  firewalls_managed: 'Firewalls Under Management:',
  first_known: 'First Known',
  first_name: 'First Name',
  first_seen: 'First Seen',
  flagged: 'Is Flagged',
  following_attributes_returned: 'The following attributes were returned from the SAML AuthNRequest:',
  following_attributes_returned_oauth2: 'The following attributes were returned from the User Info Endpoint:',
  food: 'Food & Beverage',
  forever: 'forever',
  forgot_password: 'Forgot Your Password?',
  free: 'free',
  free_space: 'Free Space',
  from_template_master: 'From: Template Master:',
  general_information: 'General Information',
  generate_prepaid_vouchers: 'Generate pre-paid vouchers',
  generate_voucher_success: 'Your voucher(s) have been created. You will receive an email confirmation shortly.',
  generate_vouchers: 'Generate Vouchers',
  generic: 'Generic',
  generic_error: 'An error occurred.',
  geo_ip_description: 'Use a Geo-IP Filter template to define which countries to block or allow by this policy',
  geoip_blocked_session: 'Session blocked by Geo-IP',
  geoip_create_fail: 'Failed to create Geo IP.',
  geoip_create_success: 'Geo IP successfully created.',
  geoip_update_fail: 'Failed to update Geo IP.',
  geoip_update_success: 'Geo IP successfully updated.',
  get: 'GET',
  get_billing_data_failed:
    'We are unable to get the data for this page. Please try again and contact Arista Edge Threat Management Sales if you continue to experience issues',
  get_notification_profile_failed: 'Get Notification Profile Failed',
  get_screenshot: 'Get Screenshot',
  get_started: 'get started',
  get_status: 'Get Status',
  get_support: 'Get Support',
  get_upgrade_price_failed: 'Unable to get the upgrade price. Please refresh and try again',
  getting_started_untangle_go:
    "<a href='https://support.edge.arista.com/hc/en-us/articles/1500003534002-Getting-Started-with-Untangle-Go-mobile-app-' target='_blank'>Getting started with Untangle Go</a>.",
  global: 'Global',
  global_configuration: 'Global Configuration',
  global_templates: 'Global Templates',
  go_back: 'Go Back',
  gold_partner: 'Gold Partner',
  government: 'Government / Public Administration',
  group_conditions: 'Group Conditions',
  group_description: 'Group Description',
  group_name: 'Group Name',
  group_not_found: 'Policy Not Found',
  hardware_appliances: 'Hardware Appliances',
  headers: 'Headers',
  healthcare: 'Healthcare & Pharmaceuticals',
  help: 'HELP',
  higher_education: 'Higher Education',
  home: 'Home / Personal',
  home_protect_basic: 'Home Protect Basic',
  home_protect_plus: 'Home Protect Plus',
  hospitality: 'Hospitality',
  hospitality_and_retail: 'Hospitality & Retail',
  host_count: 'Host Count',
  host_details: 'Host Details',
  host_type: 'Host Type',
  hosts: 'Hosts',
  hosts_selector_lookup: 'Lookup hostname, IP, MAC address or OS',
  http_user_agent: 'HTTP User Agent',
  icon: 'Icon',
  ignore_failure: 'Failed to ignore selected {0}',
  ignore_for_now: 'Ignore for now',
  ignore_success: 'Selected {0} ignored successfully',
  immediately: 'Immediately',
  import: 'Import',
  in_sync: 'In Sync',
  incoming_alerts: 'incoming alerts',
  incompatible_product_license:
    'This product cannot be installed on the appliance as it is incompatible with the existing product license.',
  industry: 'Industry',
  info: 'Info',
  info_alerts:
    'List of alerts generated by appliances. Frequent/repeated alerts are aggregated over a 15 minute period',
  info_appliance_map: 'Map of appliances locations',
  info_appliance_policies: 'List of policies associated to this appliance.',
  info_appliances_grid: 'List of appliances',
  info_audit_history: 'List of account activities',
  info_blocked_threats: 'List of threats and associated metadata that have been blocked by Virus Blocker app',
  info_cloud_backups: 'List of Cloud Backups',
  info_equals: 'Equals a defined value',
  info_greater: 'Is greater than a defined value',
  info_greater_or_equal: 'Is greater or equals a defined value',
  info_in: 'Is in an object group',
  info_intrusions_detected: 'List of up to 25 intrusions detected by Intrusion Prevention Service',
  info_less: 'Is less than a defined value',
  info_less_or_equal: 'Is less or equals a defined value',
  info_match: 'Matches an object',
  info_network_performance:
    'List of appliances and associated network performance. Click on an appliance to see additional details',
  info_not_equals: 'Does not equal a defined value',
  info_not_in: 'Is not in an object group',
  info_not_match: 'Does not match an object',
  info_policy_analytics: 'Policy Analytics info',
  info_wan_link_information: 'Historical information related to WAN link usage and performance.',
  input_a_comma_separated_list_of_ips_cidrs: 'Input a comma separated list of IPs/CIDRs or ranges',
  input_placeholder_text: 'example.com',
  input_vlan_tags: 'Please enter your VLAN Tags',
  inside_untangle_blog: 'Blog',
  inside_untangle_blog_text: 'Get the Latest Security Trends, News, Tips & Tricks to Keep Your Network Secure',
  installed_software: 'Installed Software',
  integrations: 'Integrations',
  interface_id: 'Interface ID',
  interface_offline: 'Offline WAN interface detected',
  interface_type: 'Interface Type',
  internal_error_occurred: 'An internal error occurred. Please contact Arista Edge Threat Management Sales.',
  internal_server_error: 'Internal server error',
  intrusion_info: 'Intrusion Info',
  intrusions_detected: 'Intrusions Detected',
  intrusions_detected_last_30_days: 'Intrusions Detected - Last 30 Days',
  invalid_backup_file_version: 'The backup file version is not supported on this appliance.',
  invalid_coupons_note: 'Note: Coupon(s) {invalidCoupons} do not apply to the current order',
  invalid_credit_card_number:
    'Your credit card number is invalid. Please try re-entering it, or contact your credit card provider directly.',
  invalid_csrf_token: 'An invalid security token was sent. Please try the request again.',
  invalid_information: 'Invalid information provided',
  invalid_json: 'Invalid Json format',
  invalid_name: 'Invalid name',
  invalid_notification_profile_emails:
    'Unable to create notification profile. Email address must be a user of your organization.',
  invalid_policy_condition: 'Invalid Policy Condition',
  invalid_recaptcha: 'Invalid reCAPTCHA',
  invalid_uid: 'Invalid UID',
  invalid_voucher: 'The voucher you entered is invalid.',
  invitation_missing: 'Missing invitation info for this partner - please delete and try re-adding',
  invite: 'Invite',
  invite_body: 'Please click the Confirm button below to complete the invitation process.',
  invite_title: 'Confirm Invitation',
  invited_email: 'Email address you want to invite',
  invites_being_resent: 'Invites are being resent to the selected users',
  invoice_balance: 'Invoice Balance',
  invoice_date: 'Invoice Date',
  invoice_number: 'Invoice Number',
  invoice_total: 'Invoice Total',
  invoices: 'Invoices',
  ip: 'IP',
  ip_address_hint: 'e.g. 192.168.1.0/24,192.168.2.0/24',
  ip_addresses: 'IP Addresses',
  ip_info: 'IP Info',
  ip_location: 'IP Location',
  is_pre_paid: 'Is Pre-Paid',
  iso_installer: 'ISO Installer (Graphical mode)',
  issue: 'Issue',
  item_delete_fail: 'Warning: Could not delete item(s)',
  item_dependant_items: 'Item(s) have the following dependencies:',
  job_id: 'Job ID',
  keep_current_settings: 'Keep current settings',
  keep_in_sync: 'Keep in Sync',
  keep_in_sync_hint:
    'If this setting is enabled, target appliances will be automatically synced with the master appliance whenever a change is detected on the master appliance (with the exception of network configuration changes).',
  label: 'Label',
  label_not_assigned: 'Label not assigned',
  label_not_set: 'Label Not Set',
  last_login: 'Last Login',
  last_name: 'Last Name',
  last_scan_results: 'Last Scan Results',
  last_session: 'Last Session',
  last_sync_md5: 'Last Sync MD5',
  last_sync_status: 'Last sync initiated at {syncTime}. View status in ',
  last_synced: 'Last Synced',
  latest: 'Latest',
  latitude: 'Latitude',
  learn_more: 'Learn More',
  leave_organization: 'Leave Organization',
  legal: 'Legal Services',
  license_entitled: 'License Entitled',
  license_setup_not_located:
    'An appliance with this serial number was not located in our system.  The appliance will be added to your account when it connects',
  licensed_devices: 'Licensed Devices',
  list_of_ips_cidrs: 'Comma separated list of IPs/CIDRs or ranges',
  list_of_ports_or_ranges: 'Comma separated list of ports or port ranges',
  loading_remote_access: 'Loading Remote Access',
  location: 'Location',
  lock_workstation: 'Lock Workstation',
  logged_in_as: 'Logged in As: ',
  logged_in_from: 'Logged In From:',
  logged_users: 'Logged on Users',
  login: 'Log In',
  login_attempt_locked_out:
    'You have been locked out of your account for {loginAttempts} unsuccessful login attempts.  You may try to log in again after {lockoutMinutes} minutes.',
  login_email_verify_complete: 'Your email address has been verified. You are free to access your Arista ETM account.',
  login_email_verify_error:
    'Your activation token or email is not valid. Please check your email account and try again or resend the verification email.',
  login_email_verify_incomplete:
    'Your account profile is not complete. Please check your email and verify your email address, or resend the verification email.',
  login_email_verify_resend: 'Verification email has been re-sent.',
  login_failed: 'Incorrect username or password. Please try again.',
  login_mfa_verification_required_email:
    'This account has <strong>two-factor authentication</strong> enabled. Please enter the verification code sent to the email associated with your account.',
  login_mfa_verification_required_totp:
    'This account has <strong>two-factor authentication</strong> enabled. Please enter the verification code from your TOTP authenticator application.',
  login_password_reset_form: 'Please enter your new password.',
  login_sso_required:
    'This account requires SSO Login via Google or Microsoft.  Please use the appropriate login option',
  login_sso_required_google: 'This account requires SSO Login via Google',
  login_sso_required_microsoft: 'This account requires SSO Login via Microsoft',
  login_sso_required_organization: 'This account requires SSO Login via your organization name',
  login_url: 'Login URL (HTTP-REDIRECT)',
  login_welcome_back: 'Welcome back, ',
  login_welcome_downloads:
    'Access <a href="{baseUrl}download/ng-firewall" target="_blank">Downloads</a> with ETM Dashboard',
  login_welcome_notindividual: 'Not, ',
  longitude: 'Longitude',
  lookup_geo: 'Looking up geo coordinates',
  lowest_jitter: 'Lowest Jitter - Any WAN',
  lowest_jitter_non_lte: 'Lowest jitter - Non LTE WANs',
  lowest_latency_any_wan: 'Lowest latency - Any WAN',
  lowest_latency_non_lte: 'Lowest Latency - Non LTE WANs',
  mac_address_info: 'MAC Address Info',
  mac_address_vendor: 'MAC Address Vendor',
  main_email: 'Main Email',
  main_email_info: 'Receives all email communications for this account',
  maintain_security_renew_your_subscription:
    'To maintain security and essential functionality please <a href="{baseUrl}organization/subscriptions">renew your subscription</a>.',
  make_selection: 'Please make a selection to save',
  malwarebytes_credentials: 'Malwarebytes Endpoint Security',
  malwarebytes_credentials_instructions:
    'Please enter your credentials for <a href="https://cloud.malwarebytes.com/" target="_blank">Malwarebytes Nebula Console</a> account. This will augment the \'Hosts\' section with additional information and functionality.',
  manage_disabled: 'Disabled',
  manage_full_access: 'Full Access',
  manage_message: 'Select an appliance to manage',
  manage_read_only: 'Read Only',
  manage_subscription_payfail: 'Please submit payment for {subscriptionName} before managing this subscription',
  manage_subscriptions: 'Manage Subscriptions',
  managed_service_provider: 'Managed Service Provider',
  management_address: 'Management Address',
  managing: 'Managing: ',
  manufacturer: 'Manufacturer',
  manufacturing: 'Manufacturing',
  map_platinum: 'MAP Platinum',
  map_tooltip:
    '<b>Host Name:</b> {hostName}<br/><b>UID:</b> {uid}<br/><b>Label:</b> {tag}<br/><b>Server IP:</b> {serverIp}<br/><b>Status:</b> {status}<br/><b>Host Count:</b> {hostCount}<br/><b>Location:</b> {location}',
  mask_uids: 'Mask UIDs',
  master_appliance: 'Master Appliance',
  master_appliance_not_found: ' failed to backup, master appliance not found',
  master_not_connected_to_cmd:
    'The master appliance is offline. Please ensure master appliance is online and try again.',
  md5: 'MD5',
  md5_hash_info: 'MD5 Hash Info',
  memory_details: 'Host Memory Details',
  message: 'Message',
  message_type: 'Message Type',
  method: 'Method',
  mfa: 'MFA',
  mfa_verification_failed: 'The <strong>two-factor authentication</strong> for this account failed.',
  mfw_image_e3: 'GZ (e3 Image)',
  mfw_image_e6: 'GZ (e6 Image)',
  mfw_no_subscription:
    'The appliance does not have a subscription. Connection speed is restricted and security features are disabled. Assign a subscription or <a href="{0}configurator/?sku=SR-11-SWSE-0100-1YEAR" target="_blank">click here</a> to buy a subscription.',
  mfw_setup_wizard_refresh_text:
    '<h1>Setup Wizard</h1>Please refresh the appliances once you have completed the setup wizard on the device.<br/><br/>',
  mfw_setup_wizard_text:
    '<h1>Setup Wizard</h1>Thank you for choosing Arista Edge Threat Management! A wizard will guide you through the initial setup and configuration of the device.<br/><br/>',
  mfw_upgrade_51: 'In order to access this, please upgrade to version 5.1 or above using the top menu',
  micro_edge: 'Micro Edge',
  micro_edge_basic: 'Micro Edge Basic Edition',
  micro_edge_basic_nonprofit: 'Micro Edge Basic Edition Nonprofit',
  micro_edge_basic_public_sector: 'Micro Edge Basic Edition Public Sector',
  micro_edge_description:
    'Micro Edge is a lightweight network edge appliance. It can be deployed either with NG Firewall to provide a networking solution for multi-site offices, or on its own to optimize network performance. <br/><br/>Virtual appliances require a minimum of 256 MB RAM and 128MB of Storage. Please see <a href="https://support.edge.arista.com/hc/en-us/articles/360022862034-Untangle-SD-WAN-Router-System-Requirements" target="_blank">Micro Edge System Requirements</a> for more information.',
  micro_edge_policies: 'Micro Edge Policies',
  micro_edge_security: 'Micro Edge Security Edition',
  micro_edge_security_nonprofit: 'Micro Edge Security Edition Nonprofit',
  micro_edge_security_public_sector: 'Micro Edge Security Edition Public Sector',
  micro_edge_templates: 'Micro Edge Templates',
  micro_edge_vmdk_info: 'You will need to download and install both of these files',
  mining: 'Mining',
  missing_templates: 'Following template configurations: {policyTypes} were not successfully created',
  mobile: 'Untangle Go (Mobile)',
  mobile_beta: 'Untangle Go',
  mobile_configuration: 'Mobile Configuration',
  mobile_date_paired: 'Date Paired',
  mobile_device_type: 'Device Type',
  mobile_info: 'Please select mobile devices you want to receive the notifications.',
  mobile_number: 'Mobile Number',
  mobile_summary:
    "Untangle Go is a mobile app for Android and iOS based devices that enables you to view information about your Edge Threat Management appliances and subscriptions including status, alerts and system resources from your mobile device. Untangle Go compliments ETM Dashboard, but is not a replacement. We welcome your <a href='https://aristaetm.featureupvote.com/' target='_blank'>feedback</a>.",
  modal_content:
    '<div style="width:100%;"><p>At Arista Edge Threat Management we take your privacy seriously. We recently<br/>made updates to our privacy policy to comply with the<br/>European Union\'s General Data Privacy Regulation. This<br/>policy explains:</p><ul><li>What information we collect and why we collect it.</li><li>How we use the information.</li><li>How we access, update, or remove the information.</li></ul> <p>We strive to make this policy simple to read<br/>and understand. Please read and review the policy here:<br/><a href="https://www.arista.com/en/privacy-policy" target="_blank">https://www.arista.com/en/privacy-policy</a><br/><br/>Please confirm you\'ve reviewed the policy and provide<br/>consent to Untangle to use your personal data as<br/>detailed in our privacy policy.</p><div style="text-align: center; padding-top: 10px;"></div></div>',
  model: 'Model',
  most_bandwidth_any_wan: 'Most available bandwidth - Any WAN',
  most_bandwidth_non_lte: 'Most available bandwidth - Non LTE WANs',
  msp: 'MSP',
  msp_authorized: 'MSP Authorized',
  msp_gold: 'MSP Gold',
  msp_silver: 'MSP Silver',
  my_info: 'My Info',
  my_organization: 'My Organization',
  name_on_account: 'Name on the Account',
  nat_description: 'Use NAT rules to apply Network Address Translation (NAT) to specific traffic flows',
  negative_price_explanation: 'Negative amount will be transferred to your credit balance.',
  network_average: 'Network Average',
  network_details: 'Network Details',
  network_interfaces_create_fail: 'Failed to create network interface.',
  network_interfaces_create_success: 'Network interface successfully created.',
  network_interfaces_update_fail: 'Failed to update network interface.',
  network_interfaces_update_success: 'Network interface successfully updated.',
  network_map: 'Network Map',
  network_name: 'Network Name',
  network_not_found: 'Network not found',
  network_performance: 'Network Performance',
  network_selector_lookup: 'Lookup network name',
  network_summary: 'Network Summary',
  networks: 'networks',
  never: 'Never',
  new_product: 'New Product',
  next: 'Next',
  next_step: 'Next Step',
  ng_firewall: 'NG Firewall',
  ng_firewall_complete: 'NG Firewall - Complete',
  ng_firewall_homepro: 'NG Firewall HomePro',
  ng_image_img: 'IMG (USB Image)',
  ng_image_iso: 'ISO (Installation Image)',
  ng_image_linksys32X_factory: 'IMG (Linksys WRT32X beta) Factory',
  ng_image_linksys32X_upgrade: 'IMG (Linksys WRT32X beta) Upgrade',
  ng_image_linksys1900_factory: 'IMG (Linksys WRT1900ACS beta) Factory',
  ng_image_linksys1900_upgrade: 'IMG (Linksys WRT1900ACS beta) Upgrade',
  ng_image_linksys3200ACM_factory: 'IMG (Linksys WRT3200ACM beta) Factory',
  ng_image_linksys3200ACM_upgrade: 'IMG (Linksys WRT3200ACM beta) Upgrade',
  ng_image_ova: 'OVA (VMware Appliance)',
  ng_image_vdi: 'VDI (Oracle VirtualBox)',
  ng_image_vmdk: 'VMDK (VMWare Image)',
  ngfw_iso_description:
    'Use the graphical installer for systems with high definition video output. This version runs a graphical desktop environment for local administration.',
  ngfw_no_complete_subscription:
    'The appliance does not have a Complete subscription. Advanced security and connectivity features are disabled, and ETM Dashboard management functions are limited. Assign a subscription or <a href="{0}cart/" target="_blank">click here</a> to buy a subscription.',
  ngfw_ova_description:
    'Requires ESX version 6.5 build 3 or newer. For installation and setup instructions refer to the <a href="https://wiki.untangle.com/index.php/Untangle_Virtual_Appliance_on_VMware" target="_blank">NG Firewall for VMware setup guide</a>.',
  ngfw_serial_description:
    'Use the serial console installer for systems with serial console. This includes the Q4, Q8, and Q8W hardware appliances.',
  ngfw_templates: 'NGFW Templates',
  nmap_port_scan:
    'You are about to execute an NMAP scan on the selected hosts. The results will be displayed once the scan completes.',
  no_active_accounts: 'This user has no active accounts.',
  no_appliance_found: 'Appliance not found',
  no_appliances: 'No Appliances',
  no_appliances_assigned: 'No Appliances Assigned',
  no_backups_available:
    'No backups available, see <a target="_blank" href="https://wiki.untangle.com/index.php/Configuration_Backup">Configuration Backup</a>',
  no_compatible_appliances_available: 'No compatible appliances are available.',
  no_compatible_mfw_appliances_available_for_policy:
    ' In order to receive a {policy} policy, the appliance must have a subscription and have software version >= {version}',
  no_complete_license_on_master: ' failed to Restore. Master appliance does not have a complete License',
  no_conditions_set: 'No conditions set',
  no_data: 'No data available',
  no_endpoint_security:
    'No Endpoint Security Software detected. Learn more about using Arista Edge Threat Management with Endpoint Security Software <a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" target="_blank">here</a>.',
  no_headers_set: 'No Headers Set',
  no_host_selected: 'Please select a host to view',
  no_info_billing_info_tab:
    'You have no payment details on file. You can update your payment details here after placing your first order.',
  no_item_selected: 'Please select at least one item from the list',
  no_label: 'No label',
  no_mfw_backups_available: 'No backups available.',
  no_name: 'No name',
  no_paypal_found: 'No active PayPal billing agreement found. Use the button below to create one.',
  no_product_selected: 'A product must be selected to generate vouchers for',
  no_provider:
    'Integrate with your Endpoint Security solution to extend your host management and see additional information. Learn more about using Arista Edge Threat Management with Endpoint Security software <a href="https://support.edge.arista.com/hc/en-us/articles/360012766294-Managing-hosts-in-Command-Center" target="_blank">here</a>.',
  no_record_found: 'The record you are looking for was not found. It may have been removed.',
  no_results_found: 'No Results Found',
  no_saml_test_results_found: 'No SAML test results were found. Please check your SAML settings.',
  no_subscription_found: 'Subscription not found',
  no_terms: 'No active credit agreement found. To apply for terms, please contact Arista Edge Threat Management Sales.',
  no_uid_access: 'User does not have access to this appliance',
  no_zuora_account_for_customer: 'Failed to create voucher. You have no payment method defined. Please contact Sales!',
  non_complete_appliance_string: 'The Master Appliance must be upgraded to a Complete license before syncing.',
  none_entered: 'None Entered',
  nonprofit: 'Nonprofit',
  nonprofit_complete: 'Nonprofit - Complete',
  not_allowed: 'This account does not have permissions to perform the requested operation',
  not_applicable: 'N/A',
  not_assigned: 'Not Assigned',
  not_available: 'Not Available',
  not_connected_to_cmd: 'The appliance is offline',
  not_enough_terms: 'You do not have enough terms credit for this purchase. Please select an alternate payment method.',
  not_exempt: 'Not Exempt',
  not_found: 'Not Found',
  not_on_line: 'Offline - Cannot Configure',
  not_renewing: 'Not Renewing',
  not_synced: 'Not Synced',
  notes: 'Notes',
  notification_log: 'Notification Log',
  notification_profile: 'Notification Profile',
  notification_profile_create_error: 'Failed to create the notification profile.',
  notification_profile_create_success:
    'Successfully created the new notification profile. The changes may take up to 20 minutes to propagate.',
  notification_profile_description: 'Notification profile description',
  notification_profile_edit_error: 'Failed to edit the notification profile.',
  notification_profile_edit_success:
    'Successfully edited the notification profile. The changes may take up to 20 minutes to propagate.',
  notification_profile_emails:
    'Notification profile can only be created for emails that are users of your organization.',
  notification_profiles: 'Notification Profiles',
  notification_profiles_select_error: 'Select one or more notification profiles',
  notification_profiles_select_text: 'Select the notification profiles for this alert.',
  notifications: 'Notifications',
  now: 'Now',
  now_available: 'Now Available',
  nps_dislike: 'Not at all likely',
  nps_like: 'Extremely likely',
  nps_title: 'How likely are you to recommend Arista Edge Threat Management to a friend or colleague?',
  number: 'Number',
  number_of_appliances: 'Number of Appliances',
  number_of_attempts: 'Number of Attempts',
  oauth_save_failed: 'Failed to update OAUTH preferences',
  oauth_save_success: 'OAUTH settings have been successfully updated',
  oauth2: 'OAuth2 / OpenID',
  oauth2_config: 'OAuth2 / OpenID configuration',
  oauth2_sign_in_redirects:
    'Your OAuth2 provider may require sign-in redirect URIs for the application.  The following URIs will need to be allowed:',
  oauth2_test_results: 'Display OAuth2 Test Results',
  oauth2_unconfigured: 'OAuth2 - Available only if configured!',
  object_description: 'Object Description',
  object_name: 'Object Name',
  objects_description: 'Create and manage objects to use in rules and conditions',
  occurrences: ' [Occurred {n} time(s)]',
  oem_ng_firewall_complete: 'OEM NG Firewall - Complete',
  online_firewalls: 'Online Firewalls:',
  openvpn: 'OpenVPN',
  operating_system: 'Operating System',
  operator: 'Operator',
  option_ach: 'ACH: {0}',
  option_credit_card: 'Credit Card: {0}',
  option_paypal: 'PayPal Account Email: {0}',
  option_terms: 'Terms. Available Credit: {0}',
  or: 'or',
  organization_config_delete_failed: 'Failed to delete SSO organization configuration',
  organization_config_delete_success: 'SSO organization configuration has been deleted successfully',
  organization_info: 'Organization Info',
  organization_login: 'Organization Login',
  organization_login_type: 'Organization Login Type',
  organization_login_unconfigured: 'Organization Login - Available only if configured!',
  organization_name: 'Organization Name',
  organization_requires_mfa: 'An organization you belong to requires two-factor authentication',
  organization_save_failed: 'Failed to save SSO organization settings',
  organization_save_success: 'Organization SSO settings have been updated successfully',
  organization_sso_login: 'Organization SSO Login',
  organizations: 'Organizations',
  orgtype_title: 'Which best describes your organization?',
  other_downloads: 'Other Downloads',
  out_of_sync: 'Out of Sync',
  overview: 'Overview',
  owned_by_redeemer: 'Owned by Redeemer',
  owner_location: 'Owner Location',
  page_title_app: '{app} Policies',
  pager_duty: 'Pager Duty',
  pagerduty_configuration: 'PagerDuty Configuration',
  pagerduty_info:
    'This template is for PagerDuty Events API V2. For more information <a href="https://v2.developer.pagerduty.com/docs/send-an-event-events-api-v2" target="_blank">click here.</a>',
  pair_device: 'Pair Device',
  partner_city_lat_long: 'You must enter your city and country in order to lookup the latitude and longitude',
  partner_dashboard: 'Partner Dashboard',
  partner_email_vars:
    '<h3>You can use the following variables in the field(s) below:</h3><ul>' +
    '<li><strong>%account.company%</strong> - The name of the company on your account</li>' +
    '<li><strong>%account.name%</strong> - Your name as the owner of the account</li>' +
    '<li><strong>%account.email%</strong> -  Your email address as the owner of the account</li>' +
    '<li><strong>%acceptLink%</strong> -  The accept invitation link</li>',
  partner_geocode_failed:
    "We could not geocode your address - please enter your latitude and longitude manually. To get your latitude and longitude, right-click on your location in Google Maps, select 'What's Here', and they will be listed in the following format (lat, long): 37.374427, -122.029531.",
  partner_information: 'Partner Information',
  partner_information_message:
    'All information is used only for display on the ETM Dashboard <a href="{0}partners/find-a-partner" target="_blank">Find a Partner map</a>',
  partner_information_save_failed: 'Failed to update partner information.',
  partner_information_save_success: 'Partner Information have been successfully updated.',
  partner_portal: 'Partner Portal',
  partner_program: 'Partner Program',
  partner_program_text: 'Enjoy Great Benefits and Simplified Management of All Your Clients',
  partner_sales:
    'Over the last 12 months, your sales were {total_sales}, which puts you in our <b>{partner_level}</b> level, which comes with <b style="font-size: 18px">{partner_percent}%</b> off software purchases. ',
  partner_sales_no_level:
    'Over the last 12 months, your sales were {total_sales} with a discount of <b style="font-size: 18px">{partner_percent}%</b> off software purchases. ',
  password_confirm: 'Confirm Password',
  password_update_success:
    'Your information was updated successfully. For your security, please log in again with your new credentials.',
  password_updated: 'Your password was successfully updated.',
  past_reboot_time: 'Cannot schedule a reboot time in the past',
  payload: 'Payload',
  payload_vars:
    '<h3>You can use the following variables in the field(s) below:</h3><ul>' +
    '<li><strong>%event%</strong> - JSON body of the entire event object including envelope info</li>' +
    '<li><strong>%event.HTML%</strong> - body of the entire event object including envelope info converted to HTML</li>' +
    '<li><strong>%eventstring%</strong> -  body of the entire event object including envelope info encoded as escaped JSON string</li>' +
    '<li><strong>%event.message%</strong> - event message string containing summary of the event</li>' +
    '<li><strong>%event.body%</strong> - JSON event payload excluding envelope info</li>' +
    '<li><strong>%event.bodyHTML%</strong> - event payload excluding envelope info converted to HTML</li>' +
    '<li><strong>%event.bodystring%</strong> - event payload excluding envelope info, encoded as escaped JSON string</li>' +
    '<li><strong>%eventsource%</strong> - event source - if event was generated by an appliance, UID of the appliance, Account ID otherwise</li>' +
    '<li><strong>%eventsource.IP%</strong> - the IP address from which the event was received</li>' +
    '<li><strong>%event.type%</strong> - event type (e.g. alert, audit, etc)</li>' +
    '<li><strong>%eventsource.label%</strong> - label given to an appliance in ETM Dashboard</li></ul>',
  payment_declined:
    'Your payment method was declined. Please try another payment method or contact your credit card provider directly.',
  payment_include_credit_balance: ' (after using available Credit Balance)',
  payment_info: 'Payment Info',
  payment_info_default_payment_method: 'Current Payment Method',
  payment_info_outstanding_balance: 'Outstanding Balance',
  payment_info_tax_number: 'Tax/VAT Number',
  payment_method_declined: 'Your payment method was declined. Please contact your payment provider directly.',
  payment_method_info: 'Payment Method Info',
  paypal: 'PayPal',
  paypal_method: 'PayPal Account Email: ',
  pending: 'Pending',
  pending_verification: 'Pending Verification',
  perform: 'Perform',
  permitted_appliances: 'Permitted Appliances',
  phish_blocker: 'Phish Blocker',
  phone: 'Phone',
  phone_number: 'Phone Number',
  platform: 'Platform',
  platinum_partner: 'Platinum Partner',
  please_configure_your_condition: 'Please configure your condition',
  please_contact_support: 'Please contact support at',
  please_enter_message: 'Please type in a message to send to the host:',
  please_provide_additional_info: 'Please provide this additional information to the support team',
  please_refresh_browser: 'Please refresh your browser and try again.',
  please_select_a_source_appliance: 'Please select a source appliance',
  please_select_appliance: 'Please select an appliance',
  please_select_target_appliances: 'Please select target appliance(s)',
  please_wait: 'Please wait ...',
  policies_action_policy_page: '{policy-page} | {action} {policy-page}',
  policies_category_type: '{category} | {type}',
  policies_description:
    'Create and manage policies to maintain a consistent configuration across a deployment of security appliances',
  policy_analytics: 'Policy Analytics',
  policy_cloned_failure: 'Failed to clone policy',
  policy_cloned_success: 'Successfully clone policy',
  policy_conditions_info: 'Conditions which determine when this policy gets applied',
  policy_conditions_validation_failure: 'At least one condition is not valid. Please fix it before save.',
  policy_create_failure:
    'Failed to create a new policy from the appliance. Please ensure the policy manager application and this application are installed on your appliance.',
  policy_create_success: 'Successfully created new policy from the appliance.',
  policy_delete_failure: 'Failed to remove the selected policies.',
  policy_delete_success: 'The selected policies were removed successfully.',
  policy_description: 'Policy Description',
  policy_group_dependency: 'Unable to delete as other policies are depending on.',
  policy_manager_alert_text: 'Policy Manager must be installed on the appliance to leverage this functionality.',
  policy_manager_flows: 'Policy Manager - Flows',
  policy_manager_objects: 'Policy Manager - Objects',
  policy_manager_policies: 'Policy Manager - Policies',
  policy_manager_update_fail: 'Failed to update Policy Manager.',
  policy_manager_update_success: 'Policy Manager successfully updated.',
  policy_name: 'Policy Name',
  policy_name_validation: 'Policy name should not be empty and should not contain more than 100 characters',
  policy_objects: 'Policy Objects',
  policy_order: 'Policy Order',
  policy_push_initiated: 'Policy push has been initiated.',
  policy_syncing_initiated: 'Policy syncing has been initiated',
  policy_update_failure: 'Failed to update the selected policies.',
  policy_update_success: 'Successfully updated the selected policy.',
  policymanager_session_blocked: 'Session blocked by Policy Manager',
  policymanager_session_rejected: 'Session rejected by Policy Manager',
  port_forward_description:
    'Use port forward rules to redirect incoming traffic by translating the destination address',
  port_number: 'Port Number',
  port_scan: 'Port Scan',
  post: 'POST',
  postal_zip_code: 'Postal/ZIP Code',
  price_with_discount: 'Price With Discount',
  product: 'Product',
  product_installed: 'This product is already installed on the appliance.',
  product_line: 'Product Line',
  product_name: 'Product Name',
  product_upgrade_invalid: 'The product to upgrade to is invalid',
  professional: 'Professional / Technical Services',
  profile_not_found: 'Profile not found',
  protect: 'Protect',
  provision: 'Provision',
  provision_appliance: 'Provision Appliance',
  public_cloud_deployments: 'Public Cloud Deployments',
  public_sector_complete: 'Public Sector - Complete',
  purchase_order_number: 'Purchase Order Number',
  push_appliance_wan_policies: 'Push Appliance WAN Policies',
  push_policy: 'Push Policy',
  push_template: 'Push Template',
  qualifying_institution: 'Qualifying Institution',
  quantity: 'Quantity',
  quarantined: 'Quarantined',
  queued: 'Queued',
  quick_filter_text: 'Showing {0} of {1} entries',
  quota_usage: 'Quota / Usage',
  real_estate: 'Real Estate',
  reboot_info: 'Upon appliance reboot, any scheduled reboot is lost',
  reboot_now: 'Reboot Now',
  reboot_now_successful: 'Your appliance is rebooting, features may not be available until it is back online',
  reboot_options: 'Reboot Options',
  reboot_scheduled: 'Scheduled Reboot',
  received: 'Received',
  recent_hosts: 'Recent Hosts',
  recent_hosts_info:
    'List of hosts that were active within the last 12 hours. Click on a host to see additional details',
  recipient_email_addresses: 'CSV list of recipient email addresses',
  recommendation: 'Recommendation',
  redeem: 'Redeem',
  redeem_voucher: 'Redeem Voucher',
  redeem_voucher_failure: 'Failed to redeem voucher.',
  redeem_voucher_success: 'Voucher was successfully redeemed',
  redeemed_by: 'Redeemed By',
  register_now: 'Register Now',
  registration_failed: 'Registration failed.',
  registration_success: 'Registration Success',
  registration_success_simple: 'You have successfully created an account. Please log in using your credentials.',
  registration_success_with_verification:
    'You have successfully created an account but it must be verified. You will receive an email to the address provided with instructions to complete the setup.',
  reinstate: 'Reinstate',
  reinstate_popup_message:
    'The cost to reinstate this subscription is <strong>{renewCost}</strong>.<br/>Your current balance is <strong>{balance}</strong>. <br/>Your subscription will be reinstated as of the term end date and you will be charged. <br/>Are you sure you want to reinstate this expired subscription?',
  reinstate_subscription: 'Reinstate Subscription',
  release_candidate: 'Release Candidate',
  release_notes:
    '<a target="_blank" href="https://support.edge.arista.com/hc/en-us/articles/360050838353">Release Notes</a>',
  religious: 'Religious',
  remove_ach: 'Remove ACH',
  remove_ach_confirm: 'Are you sure you want to remove the selected entry from your account?',
  remove_appliance: 'Remove Appliance',
  remove_appliance_error: 'Error removing selected appliance',
  remove_appliance_failure: 'Failed to remove the appliance from the network.',
  remove_appliance_success: 'Removed the selected appliance successfully',
  remove_appliance_totp: 'Remove Appliance TOTP',
  remove_appliances: 'Remove Appliances',
  remove_confirm: 'Are you sure you want to remove selected items?',
  remove_credit_card: 'Remove Credit Card',
  remove_network_appliance_success: 'Successfully removed the appliance from the network.',
  remove_sub_failed: 'We could not update your subscription - no changes have been made.',
  remove_sub_success: 'The subscription has been successfully unassigned.',
  remove_task: 'Remove Task',
  remove_unused_condition: 'Remove unused condition',
  remove_unused_condition_group: 'Remove unused condition group',
  remove_unused_object: 'Remove unused object',
  remove_unused_object_group: 'Remove unused object group',
  remove_unused_policy: 'Remove Unused Policy',
  remove_unused_rule: 'Remove Unused Rule',
  remove_unused_template: 'Remove Unused Template',
  remove_user_fail: 'Unable to remove the users.',
  remove_user_success: 'Users have been successfully removed.',
  remove_users: 'Remove Users',
  renew_license: 'Renew License',
  renew_now: 'Renew Now',
  renew_popup_message:
    'The cost to renew this subscription is <strong>{renewCost}</strong>.<br/>Your current balance is <strong>{balance}</strong>. <br/>Your subscription will be renewed as of the term end date and you will be charged. <br/>Are you sure you want to renew this subscription?',
  renew_sub_failed:
    'Unable to renew the subscription you have selected. Please verify payment information and try again or contact Arista Edge Threat Management Sales for more information.',
  renew_sub_success: 'The selected subscription has successfully been renewed.',
  renew_subscription: 'Renew Subscription',
  renewal_date: 'Renewal Date',
  renewal_period: 'Renewal Period',
  renewal_price: 'Renewal Price',
  renewal_quote: 'Renewal Quote',
  renewal_quote_failed:
    'Unable to retrieve a renewal quote for the subscription you have selected.  Please contact Arista Edge Threat Management Sales for more information.',
  renewal_quote_success:
    'Your quote is ready to view.  It will open in another tab or window when you select the View Quote button.',
  renewal_quote_view_quote: 'View Quote',
  reports_audit_history: 'Audit History',
  request_body: 'Request Body',
  request_copy_failed:
    'Your request failed. Please contact Arista Edge Threat Management Support for more information.',
  request_copy_success:
    'We received your request. An email will be sent to the account owner with the requested information.',
  request_electronic_account_record: 'Request Electronic Account Record',
  request_tax_exempt_status: 'Request Tax Exempt Status',
  requests: 'Requests',
  require_all_users_two_factor: 'Require all users to use two-factor authentication',
  requires_security_license: '{0} requires a security edition license',
  reseller: 'Reseller',
  resend_invite: 'Resend invite',
  resend_invite_success: 'Invites were successfully resent to the selected users.',
  resend_verification_email: 'Resend Verification Email',
  resent_invite_fail: 'Failed to resend invites to the selected users',
  reset: 'Reset',
  reset_email_template_fail: 'There was an error resetting the email template',
  reset_email_template_success: 'Email template reset successfully',
  reset_password: 'Reset Password',
  reset_password_failed: 'Failed to reset password.',
  reset_password_msg: 'Please enter your email address below. You will receive a link to reset your password.',
  reset_password_success:
    'If an account associated with this email address exists, an email will be sent with further instructions to reset your password.',
  reset_status: 'Reset initiated at {syncTime}. View status in ',
  reset_to_factory: 'Reset To Defaults',
  resolve: 'Resolve',
  resources: 'Resources',
  restore_appliance_from_backup: 'Restore Appliance From Backup',
  restore_called: 'Restore backup command sent',
  restore_failure: ' appliance(s) did not restore correctly. Make sure all appliances are online before trying again',
  restore_instructions:
    'This operation will restore the selected appliances with the backup shown.{settingsExceptions} Please ensure all appliances you wish to restore are online. Are you sure you would like to proceed?',
  restore_instructions_exceptions: ' All settings will be restored except {settingsExceptions}.',
  restore_success: 'Appliance restore successfully initiated',
  restore_warning:
    'Restoring <strong>interfaces</strong> can lead to network connectivity issues! <br /> Restoring <strong>accounts</strong> may prevent accessing the admin!',
  retail: 'Retail',
  retry: 'Retry',
  revert: 'Revert',
  review: 'Review',
  role: 'Role',
  role_admin: 'Administrator - No Access',
  role_admin_account_full: 'Administrator - Full Access',
  role_admin_account_read: 'Administrator - Read Access',
  role_appliance_account_full: 'Appliance Admin - Full Access',
  role_appliance_account_read: 'Appliance Admin - Read Access',
  role_primary: 'Primary',
  role_user: 'User - No Access',
  role_user_account_full: 'User - Full Access',
  role_user_account_read: 'User - Read Access',
  routes_create_fail: 'Failed to create Static Routes.',
  routes_create_success: 'Static Routes successfully created.',
  routes_update_fail: 'Failed to update Static Routes.',
  routes_update_success: 'Static Routes successfully updated.',
  routing_key: 'Routing Key',
  rule_cloned_failure: 'Failed to clone rule',
  rule_cloned_success: 'Successfully cloned rule',
  rule_description: 'Rule Description',
  rule_name: 'Rule Name',
  rule_not_found: 'Rule not found',
  rules_description: 'Create and manage rules to use in policies',
  rules_order_description:
    'You can rearrange rules below in desired order. This will dictate the priority they are applied on the appliance(s).',
  rules_reset_initiating:
    'Rules reset initiating to all appliances in network.  Check audit history for status of your reset request.',
  rules_sync_failed: 'Sync rules failed',
  rules_sync_initiating:
    'Rules sync initiating to all appliances in network.  Check audit history for status of your sync.',
  run_analytics_error: 'Error running analytics',
  run_analytics_sent_successfully:
    'Policy analytics process started. Refresh the grid to update data once process is complete',
  run_analytics_success: 'Analytics completed',
  same_shipping_address: 'Same Shipping Address',
  saml: 'SAML',
  saml_config: 'SAML configuration',
  saml_save_failed: 'Failed to update SAML preferences',
  saml_save_success: 'SAML settings have been successfully updated',
  saml_test_results: 'SAML Test Results',
  saml_unconfigured: 'SAML - Available only if configured!',
  sample: 'Sample',
  save_appliance_setting: 'Save Appliance Setting',
  save_changes: 'Save Changes',
  save_condition: 'Save Condition',
  save_failed: 'Failed to update account preferences.',
  save_oauth: 'Save OAuth2',
  save_policy_changes: 'Save Policy Changes',
  save_policy_changes_text: 'The Policy has been modified. Do you want to save changes?',
  save_rule: 'Save Rule',
  save_rule_changes: 'Save Rule Changes',
  save_rule_changes_text: 'The Rule has been modified. Do you want to save changes?',
  save_saml: 'Save SAML',
  save_vat_number: 'Save Tax/VAT Number',
  saving_ach_payment: 'Saving ACH Payment',
  saving_credit_card: 'Saving Credit Card',
  saving_policy_template: 'Saving Policy Template',
  saving_settings_dialog: 'We are still saving your previous settings. Please retry!',
  saving_user_permissions: 'Saving user permissions',
  savings: 'Savings',
  scan_duration: 'Scan Duration',
  scan_qrcode: 'Please scan the code with <strong>Untangle Go</strong> app',
  scan_type: 'Scan Type',
  school: 'School',
  sdn: 'Software-defined Networks',
  search: 'Search',
  seats_5: 'Up To 5',
  seats_12: 'Up To 12',
  seats_25: 'Up To 25',
  seats_50: 'Up To 50',
  seats_75: 'Up To 75',
  seats_100: 'Up To 100',
  seats_150: 'Up To 150',
  seats_250: 'Up To 250',
  seats_500: 'Up To 500',
  seats_1000: 'Up To 1000',
  seats_1000000: 'unlimited',
  seats_home: 'home',
  seats_unlimited: 'unlimited',
  security_code_not_verified:
    'We could not verify your security code. Please try re-entering it, or contact your payment provider directly.',
  security_description: 'Use security rules to filter traffic flows between network segments',
  security_id: 'Security ID',
  security_license_not_found:
    'This appliance does not have a security license. Most services are only available with a security edition license!',
  select_appliance: 'Select Appliance',
  select_appliances_restore_instructions: 'Please select one or more appliances to restore',
  select_appliances_sync_instructions: 'Select appliances to push the template to',
  select_appliances_to_restore: 'Select appliances to Restore',
  select_appliances_to_sync: 'Select appliances to sync with the master template',
  select_applications: 'Please select one or more applications',
  select_conditions: 'Select Conditions',
  select_delete_items: 'Select items to be deleted',
  select_existing_conditions: 'Select Existing Conditions',
  select_existing_rules: 'Select Existing Rules',
  select_firewall: 'Select Firewall',
  select_group: 'Select Group',
  select_host: 'Select Host',
  select_master_appliance:
    'Select the appliance that you would like to use as the master appliance. Only licensed appliances supported.',
  select_network: 'Select Network',
  select_new_product: 'Select New Product',
  select_object: 'Select Object',
  select_one_appliance: 'Select at least one appliance to create a network',
  select_organization: 'Select an organization',
  select_payment_method: 'Select payment method',
  select_product: 'Please select a product to generate vouchers for:',
  select_rules: 'Select Rules',
  select_rules_order: 'Select rules order',
  select_template_appliance: 'Select the Template appliance',
  send_invitation: 'Send Invitation',
  send_invitation_to_users: 'Sending Invitation to Users',
  send_message: 'Send Message',
  sender_email_address: 'Sender email address',
  sent: 'Sent',
  serial_installer: 'ISO Installer (Serial console)',
  serial_number: 'Serial Number',
  server_geoip: 'Server Geo-IP',
  services_reset_warning: 'This service will be reset to default settings. <br /> Do you want to continue?',
  session_blocked: 'Session blocked by Threat Prevention',
  session_expired: 'Your session has expired. Please log in again.',
  session_redirected: 'Session redirected by Threat Prevention',
  set_account_settings: 'Set Account Settings',
  set_appliance_policy: 'Set Appliance Policy',
  set_as_default: 'Set As Default',
  set_description: 'Set description',
  set_despription_message: 'Please enter a description for your subscription(s)',
  set_label: 'Set Label',
  set_label_fail: 'Failed to change the label, please make sure you have full access to this appliance',
  set_label_success: 'Successfully updated appliance label',
  set_schedule: 'Set Schedule',
  set_wifi_region: 'Set Wi-Fi Region',
  set_wifi_region_fail: 'Failed to change the Wi-Fi region',
  set_wifi_region_success: 'Successfully updated appliance Wi-Fi region',
  settings_rolledback: 'Settings rolled back',
  settings_sso: 'Single Sign-On',
  settings_sso_any_provider: 'Any provider',
  settings_sso_condition: 'For your account safety, this option is only enabled if you log in with Single Sign-On',
  settings_sso_enabled_condition: 'For your account safety, you can only select the SSO you used to login',
  settings_sso_google: 'Google',
  settings_sso_microsoft: 'Microsoft',
  settings_sso_not_required: 'Not required',
  settings_sso_requirement: 'Single Sign-On Requirement',
  settings_update_failed: 'Settings could not be changed',
  settings_updated: 'Settings successfully changed',
  setup: 'Setup',
  setup_admin_email: 'Admin email',
  setup_admin_password: 'Admin Password',
  setup_admin_username: 'Admin User Name',
  setup_agreement: "I agree to Arista Edge Threat Management's {0}",
  setup_appliance_is_master:
    'Template push was not executed because this appliance is already set as a master of an existing template',
  setup_configure: 'Set Up Your Appliance',
  setup_eula: 'End User License Agreement',
  setup_incomplete:
    'The system was unable to connect to your appliance and complete configuration.  Error {msg}.  A task has been created which will re-try to connect.',
  setup_install_type: 'Install Type',
  setup_ngfw_config: 'Enter configuration information for this appliance',
  setup_time_zone: 'Time Zone',
  severity: 'Severity',
  shaping_description: 'Use shaping rules to optimize network performance for specific traffic flows',
  share_sub_description: 'Allow another user to view and assign/unassign this subscription.',
  share_sub_failed: 'Failed to share the subscription.',
  share_sub_success: 'The subscription has been successfully shared.',
  share_sub_with_self: 'You cannot assign a subscription to yourself.',
  shared_subnets: 'Shared Subnets',
  shipping_address: 'Shipping Address',
  shipping_name: 'Shipping Name',
  show_autogenerated: 'Show autogenerated',
  show_more: 'Show more...',
  show_on_partner_map: 'Show on Partner Map',
  show_qr_code: 'Show QR Code',
  show_rules: 'Show rules for appliance',
  sign_out: 'Sign Out',
  signing_certificate_if_different: 'Signing Certificate (if different from the encryption certificate)',
  signing_out: 'Signing Out',
  silver_partner: 'Silver Partner',
  since: 'since',
  single_reboot_time_restriction: 'Only one reboot time allowed - delete the previous reboot time to have a new time',
  size: 'Size',
  sku: 'SKU',
  slack: 'Slack',
  slack_configuration: 'Slack Configuration',
  slack_info:
    'This template is for a Slack webhook. For more information <a href="https://api.slack.com/incoming-webhooks" target="_blank">click here.</a>',
  software_downloads: 'Software Downloads',
  software_it: 'Software / IT',
  source_intf_name: 'Source Interface Name',
  source_reverse_dns: 'Source Reverse DNS',
  spam_blocker: 'Spam Blocker',
  specific_lte: 'Specific WAN - LTE',
  specific_wan0: 'Specific WAN - WAN0',
  specific_wan1: 'Specific WAN - WAN1',
  specify_port_list: 'Specify a single or a list of ports, or port ranges',
  specify_port_number: 'Specify a port number',
  ssl_cert_dns_name: 'SSL Certificate DNS Name',
  ssl_inspector: 'SSL Inspector',
  sso: 'SSO',
  sso_existing_organization: 'The organization you are trying to save already exists',
  sso_required: 'SSO Required',
  sso_save_failed: 'Failed to update SSO preferences',
  sso_save_success: 'SSO settings have been successfully updated',
  sso_type: 'SSO Type',
  start_date: 'Start Date',
  start_time: 'Start Time',
  state_and_local_gov: 'State & Local Government',
  stats_pinganalyzers_create_fail: 'Failed to create Status Analyzers rules.',
  stats_pinganalyzers_create_success: 'Status Analyzers rules successfully created.',
  stats_pinganalyzers_update_fail: 'Failed to update Status Analyzers rules.',
  stats_pinganalyzers_update_success: 'Status Analyzers rules successfully updated.',
  step_n_of_3: 'Step {n} of 3',
  street_address: 'Street Address',
  student: 'Student',
  sub_already_assigned: 'This subscription is already assigned to this appliance',
  sub_charge_value: 'New Subscription Charge',
  sub_not_allowed: 'This subscription is not allowed on this appliance.',
  sub_not_assignable: 'This subscription cannot be assigned to an appliance.',
  sub_owner: '{0} has administrative control of this subscription',
  sub_redeemer: '{0} can assign and unassign this subscription',
  submit: 'Submit',
  submit_a_request: 'Submit a support ticket',
  subnets: 'Subnets',
  subscription: 'Subscription',
  subscription_assigned_expired:
    'The subscription assigned to this appliance is expired. Critical security features are disabled and your network may be at risk. | One or more subscriptions for this appliance is expired. Critical security features are disabled and your network may be at risk.',
  subscription_assigned_will_expire_day:
    'The subscription assigned to this appliance will expire in 1 day. | One or more subscriptions for this appliance will expire in 1 day.',
  subscription_assigned_will_expire_days:
    'The subscription assigned to this appliance will expire in {0} days. | One or more subscriptions for this appliance will expire in {0} days.',
  subscription_details: 'Subscription Details',
  subscription_home: 'home',
  subscription_name: 'Subscription Name',
  subscription_not_toggling:
    'Your auto renew window is past for subscription(s) {names}. Please contact Arista Edge Threat Management Sales, who will help you process your renewal preferences.',
  subscription_owned_by_redeemer: 'This subscription will be owned by the redeemer',
  subscription_term: 'Subscription term: ',
  subscription_term_1_month: 'Monthly',
  subscription_term_12_month: '1 Year',
  subscription_term_15_month: '15 months',
  subscription_term_24_month: '2 Years',
  subscription_term_36_month: '3 Years',
  subscription_term_60_month: '5 Years',
  subscription_unlimited: 'unlimited',
  subscription_upgrade: 'Subscription Upgrade',
  subscription_upgrade_failed: 'Failed Subscription Upgrade',
  subscription_upgrade_success: 'Upgrade Subscription Success',
  subscriptions: 'Subscriptions',
  subscriptions_not_updated:
    'Your subscriptions were not updated - please contact Arista Edge Threat Management Sales for more information.',
  subscriptions_not_updated_with_terms: 'Accounts with terms as default payment cannot set up auto renewal',
  subtitle:
    'Networks enable you to group together appliances to view aggregated performance data and push shared WAN routing policies.',
  success_green: '<span style="color:green;">SUCCESS</span>',
  suggest_idea: 'SUGGEST IDEA',
  swap: 'Swap',
  switch_organization_failed:
    'Failed to switch organizations. You will now be logged out. Please attempt to log back in to the desired organizations.',
  switch_organization_no_access:
    'Failed to switch organizations. You no longer appear to have access to this organization. If you believe this is an error, please contact the organization administrator.',
  switch_to: 'Switch To',
  sync: 'Sync',
  sync_access_rule_warning: 'Sync rules will overwrite the existing Access Rules on the device',
  sync_all_new_appliances_already_enabled:
    'The "Sync All New Appliances" setting can only be enabled for one template at any given time. Please disable this setting to proceed.',
  sync_cloud_vision_appliances: 'Sync Cloud Vision Appliances',
  sync_failed: 'Failed to sync target appliances.',
  sync_failed_no_complete_license_on_master:
    'Unable to sync target appliances. The master appliance does not have a Complete license.',
  sync_failure_formatted_message: '<span style="color:red;">FAILURE</span> - {error}',
  sync_failure_toast_message: 'FAILURE - One or more appliances failed to sync.',
  sync_filter_rule_warning: 'Sync rules will overwrite the existing Filter Rules on the device',
  sync_frequency: 'Sync frequency',
  sync_in_progress: 'Sync is in progress',
  sync_initiated: 'Sync Initiated',
  sync_initiated_target_appliances: 'The synchronization process has been initiated on all of the target appliances.',
  sync_now: 'Sync Now',
  sync_now_confirmation:
    'This operation will update the appliances in the target appliance list with the configurations and policies from the template master (with the exception of network configuration).',
  sync_now_could_not_be_performed: 'Sync now could not be performed',
  sync_policy_warning: 'Syncing will overwrite the {policy} settings on the device. Are you sure you want to continue?',
  sync_rule_warning: 'Sync will overwrite the existing {policyType} on the device.',
  sync_rules: 'Sync rules',
  sync_rules_to_mfw_appliances: 'Sync Rules to MFW Appliances',
  sync_service_policy: 'Synchronize Service Policy',
  sync_status: 'Sync Status',
  sync_success_toast_message: '<span style="color:green;">Success!</span> Appliance {uid} was successfully synced.',
  sync_target_with_template: 'Synchronize Target Appliance With Template',
  sync_vpn_info: 'Synchronize VPN Information',
  sync_vpn_settings: 'Sync VPN Settings',
  sync_wan_rule_warning: 'Sync rules will overwrite the existing WAN Rules and WAN Policies on the device',
  sync_wan_rules: 'Synchronize WAN Rules',
  synced: 'Synced',
  syncing: 'Syncing',
  system_details: 'System Details',
  system_setupwizard_update_fail: 'Failed to update system setup wizard.',
  system_setupwizard_update_success: 'System setup wizard successfully updated.',
  system_timezone_update_fail: 'Failed to update system timezone.',
  system_timezone_update_success: 'System timezone successfully updated.',
  system_type: 'System Type',
  system_update_fail: 'Failed to update system.',
  system_update_success: 'System successfully updated.',
  sysupgrade_failed: 'Failed sysupgrade attempt',
  target_appliance: 'Target Appliance',
  target_appliance_failure_toast_message:
    "One or more of your target appliances can't sync. Edit the template and remove misconfigured appliances.",
  task: 'Task',
  task_completed_successfully: 'The task has completed successfully',
  task_id: 'Task Id',
  tasks: 'Tasks',
  tasks_list: 'List of tasks',
  tasks_remove_failed: 'There was an error removing the selected tasks',
  tasks_remove_successful: 'The selected tasks were successfully removed',
  tax_exempt_status: 'Tax Exemption Status (US only): ',
  tax_information: 'Tax Information',
  tax_vat_number: 'Tax/VAT number. What you enter here will appear on your invoice.',
  taxes: 'Taxes',
  technical_difficulties:
    "Unfortunately we're having technical difficulties, please try again later or contact us for more information.",
  telecommunications: 'Telecommunications & Internet',
  template: 'Template',
  template_configuration: 'Template Configuration',
  template_master: 'Template Master',
  template_not_found: 'Template not found',
  template_type: 'Template Type',
  templates: 'Templates',
  templates_description: 'Create and manage service configuration templates to use in policies',
  temporary_payment_error:
    'We were unable to process your payment at this time, please try again later.  If the issue persists, please contact Arista Edge Threat Management Accounting for assistance.',
  term: 'Term',
  terms: 'Terms',
  terms_credit_balance: 'Terms Credit Balance: ',
  terms_credit_limit: 'Terms Credit Limit: ',
  terms_method: 'Terms. Available Credit: ',
  test_general: 'Test SSO Settings',
  test_oauth: 'Test OAuth2 Settings',
  test_saml: 'Test SAML Settings',
  thank_you: 'Thank You!',
  the_following_error_occurred: 'The following error occurred:',
  threat_history: 'Threat History',
  threat_info: 'Threat Info',
  threat_prevention_description:
    'Use a Threat Prevention template to define whether to apply Threat Prevention to this policy',
  threatprevention_create_fail: 'Failed to create Threat Prevention.',
  threatprevention_create_success: 'Threat Prevention successfully created.',
  threatprevention_update_fail: 'Failed to update Threat Prevention.',
  threatprevention_update_success: 'Threat Prevention successfully updated.',
  threats: 'Threats',
  threats_blocked: 'Threats Blocked:',
  throughput_50: 'Up To 50 Mbps',
  throughput_100: 'Up To 100 Mbps',
  throughput_300: 'Up To 300 Mbps',
  throughput_500: 'Up To 500 Mbps',
  throughput_unlimited: 'unlimited',
  time_based_one_time_password: 'Time-based One-Time Password',
  time_of_day_utc: 'Time of Day (UTC)',
  time_zone_change_warning: 'Note - timezone changes will not full take effect until after appliance is rebooted',
  timeout_maximum_error: 'Inactivity Logout Period cannot be over 48 hours',
  timeout_message: 'Timeout can be set between 1 minute and 48 hours',
  timeout_minimum_error: 'Inactivity Logout Period must be at least 1 minute',
  timeout_section_title: 'Inactivity Logout Period',
  timestamp: 'Timestamp',
  title: 'Networks',
  to_target_appliances: 'To: Target Appliances:',
  toggle_auto_renew_alert_message:
    '<strong>Are you sure you want to turn off Auto Renewal?</strong> <br/><br/>If the Auto Renewal date passes without a renewal payment, a new order will need to be placed and your network security applications will no longer protect your network. <br/><br/>Press "No" to keep your subscription current automatically.',
  toggle_auto_renew_fail_due_to_default_payment:
    'You cannot toggle auto renewal for subscription(s): {names}. Your default payment method has had too many consecutive failures. Please update it to turn on Auto Renewal. Contact Arista Edge Threat Management Sales for more information.',
  toggle_auto_renew_not_allowed:
    'You cannot toggle auto renewal for subscription {name}: {reason}. Contact Arista Edge Threat Management Sales for more information.',
  toggle_auto_renewal: 'Toggle Auto Renewal',
  token: 'Token',
  token_endpoint_url: 'Token Endpoint URL',
  tools: 'tools',
  top_applications: 'Top Applications',
  top_applications_bandwidth: 'Top Applications (by bandwidth) - Last 30 Days',
  top_blocked_categories_by_request: 'Top Blocked Categories (by request)',
  top_blocked_hostnames_by_request: 'Top Blocked Hostnames (by request)',
  top_blocked_sites: 'Top Blocked Sites',
  top_blocked_sites_by_request: 'Top Blocked Sites (by request)',
  top_domains: 'Top Domains',
  top_domains_by_request: 'Top Domains (by request)',
  top_hostnames: 'Top Hostnames',
  top_hostnames_bandwidth: 'Top Hostnames (by bandwidth)',
  top_hosts_last_30: 'Top Hostnames (by bandwidth) - Last 30 Days',
  total: 'Total',
  total_amount_all: '12 month amount for account (all subscriptions)',
  total_amount_shared: '12 month amount for account (shared subscriptions)',
  total_bandwidth: 'Total Bandwidth',
  total_bandwidth_30days: 'Total Bandwidth - Last 30 Days',
  total_bandwidth_last_30: 'Total Bandwidth - Last 30 days',
  total_blocked_categories: 'Total Blocked Categories',
  total_blocked_hostnames: 'Total Blocked Hostname',
  total_cost: 'Total Cost',
  total_count: 'Total Count',
  total_download: 'Total Download',
  total_number_of_policies: 'Total Number of Policies:',
  total_shared: 'Shared subs with account',
  totp: 'TOTP',
  totp_added_successful: 'The TOTP was added to the appliance successfully',
  totp_added_unsuccessful: 'TOTP was unable to be added to this appliance',
  totp_removed_successful: 'The TOTP was removed from the appliance successfully',
  totp_removed_unsuccessful: 'TOTP was unable to be removed from this appliance',
  transporation: 'Transportation',
  tunnel_vpn: 'Tunnel VPN',
  two_factor_authentication: 'Two-Factor Authentication',
  two_factor_required: 'Two-Factor Authentication Required',
  uid: 'UID',
  uid_or_serial_empty_text: 'UID or serial number',
  uid_validation: 'Invalid UID - UIDs can only contain letters A-F and numbers 0-9.',
  uids: 'Uids',
  unable_to_communicate: 'Unable to Communicate with ETM Dashboard.',
  unable_to_process_payment: 'Unable to process payment',
  unable_to_save: 'Unable to save {0}',
  unassign_policies: 'Unassign policies',
  unassign_policies_from_appliance: 'Unassing Policies from Appliance',
  unassign_policies_warning: 'Do you want to unassign all policies from selected appliances?',
  unassign_sub: 'Unassign subscription from <strong>{0}</strong>',
  unassign_sub_failed: 'We could not unassign your subscription.',
  unassign_sub_success: 'The subscription has been successfully unassigned.',
  unassign_sub_warning: '<strong>WARNING:</strong> Subscription will be unassigned from <strong>{uid}</strong>.',
  unassigned: 'Unassigned',
  unassigning: 'Unassigning',
  unexpected_error_occurred: 'An unexpected error occurred.',
  unique_name_for_rule: 'Unique name for rule',
  unique_notification_profile_name: 'Unique notification profile name',
  unknown_error: 'Unknown Error.',
  unknown_hostname: 'Unknown Hostname',
  unknown_ip: 'Unknown IP',
  unknown_mac: 'Unknown MAC',
  unknown_os: 'Unknown OS',
  unknown_platform: 'Unknown Platform',
  unknown_uids: 'Unknown Uids',
  unknown_version: 'Unknown Version',
  unlicensed_not_eligible: 'Unlicensed - Not Eligible',
  uno_application: 'UNO Application',
  uno_application_groups: 'UNO Application Groups',
  unshare_sub: 'Unshare this subscription with {0}',
  unshare_sub_failed: 'We could not unshare your subscription.',
  unshare_sub_success: 'The subscription has been successfully unshared.',
  unshare_sub_warning: '<strong>WARNING:</strong> Subscription will be unshared with <strong>{email}</strong>.',
  unsupported_device_type: 'Non MFW Appliance not supported!',
  untangle_go: 'Mobile App',
  untangle_go_mobile_app: 'Untangle Go Mobile App',
  untangle_go_text: 'View network status and alerts anytime, anywhere.',
  untangle_go_title: 'Untangle Go',
  untangle_server_error:
    'There was an unexpected error during the processing of your order. Please contact Arista Edge Threat Management Accounting (untangle-accounting@arista.com or 1-408-598-4292) for assistance.',
  untangle_support: 'Support',
  untangle_support_text: 'Need a Little Help? Find the Answers You Need in Our Support Section',
  unused_condition: 'Unused Condition',
  unused_condition_group: 'Unused Condition Group',
  unused_object: 'Unused Object',
  unused_object_group: 'Unused Object Group',
  unused_policy: 'Unused Policy',
  unused_rule: 'Unused Rule',
  unused_template: 'Unused Template',
  update_appliance_assignments: 'Update Appliance Assignments',
  update_appliance_hostname: 'Update Appliance Hostname',
  update_appliance_totp: 'Update Appliance TOTP',
  update_appliance_wifi_region: 'Update Appliance Wi-Fi Region',
  update_appliances_confirmation:
    'ETM Dashboard will check for software updates for these appliances. If one is available, your appliances will be updated. Are you sure you want to update now?',
  update_appliances_location_failed: 'Location update failed',
  update_appliances_location_success: 'Location Updated successfully',
  update_cc_failed: 'The credit card information could not be saved - please check validity of date and card number.',
  update_credit_card_confirm: 'The credit card has been successfully updated.',
  update_day_of_week: 'Day of week',
  update_default_payment_method_no_selection: 'Please make sure you selected a payment method to set as default.',
  update_domainname: 'Update your appliance domain name -- this can also cause a reboot',
  update_error: 'Unable to upgrade appliance at this time',
  update_hostname: 'Update your appliance hostname   -- changing this may cause your appliance to reboot',
  update_initiated: 'Upgrade Initiated',
  update_location: 'Update your appliance location',
  update_message: 'Select one or more appliances eligible for software update',
  update_no_datetime: 'Could not connect',
  update_none_scheduled: 'None scheduled',
  update_notification_profile_failed: 'Update Notification Profile Failed',
  update_now: 'Update Now',
  update_options: 'Update Options',
  update_payment_methods: 'Please update your payment details by going to My Account -> Billing Info',
  update_picker_info: 'Please select the day and time to perform automatic upgrades.',
  update_scheduled: 'Current Weekly Schedule',
  update_software: 'Update Software',
  update_sub_description: 'Update description',
  update_sub_description_empty: 'You have to select at least one subscription.',
  update_sub_description_failed: 'Unable to update the description for the subscription(s) you have selected.',
  update_sub_description_success: 'Description for selected subscription(s) has successfully been updated.',
  update_tax_exempt_status_failed: 'Your tax exempt request could not be sent.',
  update_tax_exempt_status_success:
    'Your tax exempt request has been received - we will send you an email with more information shortly.',
  update_tax_vat_number_failed: 'Your VAT number could not be updated.',
  update_tax_vat_number_success: 'Your VAT number was updated successfully.',
  update_wifi_region: 'Update your Wi-Fi region',
  updates: 'Privacy Policy Updates',
  updates_available: 'Updates Available',
  updating_default: 'Updating default payment',
  upgrade_confirm_message:
    'Your current subscription(s) will be cancelled and a new one will be created. Are you sure you want to proceed with the upgrade?',
  upgrade_day: 'Day must be between {0} and {1} ',
  upgrade_discount_value: 'Discount(s) amount',
  upgrade_docker_waf: 'To upgrade WAF in Docker deployments see:',
  upgrade_failed: 'Failed upgrade attempt',
  upgrade_hour: 'Hour must be between {0} and {1} ',
  upgrade_minute: 'Minute must be between {0} and {1} ',
  upgrade_no_options_available: 'There are no upgrade options available for selected subscriptions.',
  upgrade_price: '<b>Total amount that will be applied to your account: </b>',
  upgrade_price_estimate_no_taxes: '<br/>These are your estimated prices, which may not include taxes.',
  upgrade_product: 'Upgrade Product',
  upgrade_redeemed_voucher_sub_not_allowed: 'Subscriptions created for redeemed vouchers cannot be upgraded.',
  upgrade_refund_value: 'Current Subscription Refund',
  upgrade_renewal_date: '<b>New renewal date: </b>',
  upgrade_renewal_price: '<b>Total price to be charged on renewal: </b>',
  upgrade_review_price: 'Review Price',
  upgrade_selected_subs_failed:
    'Unable to upgrade one or more of the selected subscriptions. Please make sure your default payment method is valid or contact Arista Edge Threat Management Support for more information.',
  upgrade_sub_appliance: 'You cannot upgrade appliances.',
  upgrade_sub_cmdc: "The Command Center product can't be upgraded to a Complete package. Please make a new selection.",
  upgrade_sub_complete: 'Complete packages must be upgraded individually.',
  upgrade_sub_complete_exists:
    'This subscription is assigned to a server that already contains a Complete package. Please change your selection.',
  upgrade_sub_contact_untangle:
    'Selected product with 5 years renewal period cannot be upgraded here. Please contact Arista Edge Threat Management Sales for more options.',
  upgrade_sub_default_fail:
    'Unable to upgrade the subscriptions you have selected. Please attempt to upgrade separately or contact support.',
  upgrade_sub_demo_bundle: 'You cannot upgrade Demo Bundles.',
  upgrade_sub_denied: 'You cannot upgrade this subscription.',
  upgrade_sub_diff_seats: 'Your selections license different numbers of devices and must be upgraded individually.',
  upgrade_sub_diff_servers: 'Your selections are assigned to different servers and must be upgraded individually.',
  upgrade_sub_duplicate: 'Your selections contain duplicate products. Please remove the duplicates and try again.',
  upgrade_sub_has_payfail:
    'There was a recent payment failure detected on your account. Please update your payment information and try again.',
  upgrade_sub_home_protect: 'Home Protect packages must be upgraded individually',
  upgrade_sub_mfw: 'Micro Edge subscriptions must be upgraded individually.',
  upgrade_sub_multiple_complete:
    'You cannot continue the Upgrade process if among the multiple subscriptions selected, you have at least one Complete package. Please remove all Complete package selections and try again.',
  upgrade_sub_new_term: '<br/>Your subscription will start a new term.',
  upgrade_sub_nfr: 'You cannot upgrade Not For Resale products.',
  upgrade_sub_no_further: 'One or more of the selected products cannot be upgraded any further.',
  upgrade_sub_oem: 'OEM packages must be upgraded individually.',
  upgrade_sub_seats_assigned:
    'Please make sure to select all subscriptions assigned to your server in order to upgrade the number of devices.',
  upgrade_sub_together: 'The subscriptions you have selected cannot be upgraded together. Please upgrade separately.',
  upgrade_tax_value: 'Total taxes',
  upgraded_subscription: 'Upgraded the subscription.',
  upload_rate: 'Upload',
  upload_total: 'Total Upload',
  ups_error_in_processing: 'There was an error calculating UPS charges for this order',
  uri: 'Uri',
  url_admin_info: 'URL Admin Info',
  url_info: 'URL Info',
  url_owner_info: 'URL Owner Info',
  url_tech_info: 'URL Tech Info',
  use_default_settings: 'Use default settings',
  used: 'used',
  user_agent: 'User Agent',
  user_already_invited: 'User(s): {emails} are already managing this Organization.',
  user_band_conflict: 'This product has a different user band than other products on your appliance.',
  user_email_address: 'User Email Address',
  user_emails: 'User Emails',
  user_info_endpoint_url: 'User Info Endpoint URL',
  user_info_update_failed: 'Failed to update contact information.',
  user_interface: 'User Interface',
  user_invite_fail: 'Unable to invite the users.',
  user_invite_success: 'User invites sent successfully.',
  user_name: 'User Name',
  user_not_found: 'User not found',
  user_not_in_org: 'You are not a member of the organization you wish to leave',
  user_settings_saved_success: 'User settings saved successfully',
  users: 'Users',
  utilities: 'Utilities & Energy',
  valid_license: 'Valid license',
  vendor: 'Vendor',
  verification_method: 'Verification Method',
  verify_appliance_type: 'Please verify appliance type',
  version: 'Version',
  victor_ops: 'VictorOps',
  victorops_configuration: 'VictorOps Configuration',
  victorops_info:
    'This template is for the VictorOps REST Endpoint. For more information <a href="https://help.victorops.com/knowledge-base/victorops-restendpoint-integration/" target="_blank">click here.</a>',
  view_details: 'View Details',
  view_rules: 'View rules',
  virus_blocker: 'Virus Blocker',
  voucher: 'Voucher',
  voucher_already_redeemed: 'The voucher you entered has already been redeemed.',
  voucher_code_to_redeem: 'Enter the voucher code you would like to redeem',
  voucher_expired: 'The voucher you entered has expired.',
  voucher_missing_organization: 'The qualifying institution name is required for redeeming the voucher.',
  vouchers: 'Vouchers',
  vouchers_account_no_purchases:
    'There are no purchases on the account. Please complete at least one purchase to enable voucher functionality.',
  vpn_access: 'VPN Access',
  vpn_reset_initiating:
    'Initiating VPN settings reset to all appliances in network.  Check audit history for status of this reset.',
  vpn_sync_initiating:
    'Initiating VPN settings sync to all appliances in network.  Check audit history for status of this sync.',
  waf: 'Web Application Firewall',
  waf_connection_lost: 'Due to inactivity, your current connection was lost. To establish a new one, please click ',
  waf_description:
    'Web Application Firewall (WAF) is a security appliance that protects web applications from Internet threats and malicious attacks that can lead to the compromise or loss of data. You can deploy WAF as a Docker Container or installed as a software appliance using the ISO.',
  waf_docker_description:
    'The Docker Container is a deployment option for running WAF directly on a web server or any Linux distribution capable of running Docker, either on premises or in a Public Cloud instance such as Amazon AWS, Microsoft Azure, Google Cloud, or Oracle Cloud. <a href="https://support.edge.arista.com/hc/en-us/articles/4410194539159" target="_blank">Learn more</a> about the Docker Container deployment.',
  waf_iso_description:
    'The ISO deployment option is a Debian based operating system installation that can be deployed as a virtual appliance or bare metal on a server. <a href="https://support.edge.arista.com/hc/en-us/articles/4410086155799" target="_blank">Learn more</a> about the ISO deployment.',
  waf_not_supported: 'WAF currently not supported',
  waf_setup_complete: 'Succesfully added the web application firewall to your account',
  waf_setup_wizard_text:
    '<strong>Setup</strong><br/>Thank you for choosing Arista Edge Threat Management! Clicking continue will add the appliance to your account and bring you to the configuration wizard.<br/><br/>',
  waf_unable_to_connect: 'We were unable to connect to your Web Application Firewall: <b>{0}</b>',
  wan_balancer: 'WAN Balancer',
  wan_create_fail: 'Failed to create WAN Rules.',
  wan_create_success: 'WAN Rules successfully created.',
  wan_failover: 'WAN Failover',
  wan_link_information: 'WAN Link Information',
  wan_policies_create_fail: 'Failed to create WAN Policies.',
  wan_policies_create_success: 'WAN Policies successfully created.',
  wan_policies_sync_info_end: '. Policies require an associated WAN Rule to sync to device.',
  wan_policies_sync_info_start: 'Create WAN Policies for use in ',
  wan_policies_update_fail: 'Failed to update WAN Policies.',
  wan_policies_update_success: 'WAN Policies successfully updated.',
  wan_rule_criteria_specified: 'A WAN Rule must have at least one criteria specified to be valid',
  wan_rule_description: 'Use WAN rules to forward traffic via preferred links',
  wan_update_fail: 'Failed to update WAN Rules.',
  wan_update_success: 'WAN Rules successfully updated.',
  web_cache: 'Web Cache',
  web_events: 'Web Events',
  web_filter_blocked: 'Is Blocked',
  web_filter_category: 'Category',
  web_filter_description: 'Use a Web Filter template to define which URL categories to permit or deny by this policy',
  web_filter_flagged: 'Is Flagged',
  web_filter_reason: 'Reason',
  webfilter_block_session: 'Session blocked by Web Filter',
  webfilter_create_fail: 'Failed to create Web Filter.',
  webfilter_create_success: 'Web Filter successfully created.',
  webfilter_flag_session: 'Session flagged by Web Filter',
  webfilter_update_fail: 'Failed to update Web Filter.',
  webfilter_update_success: 'Web Filter successfully updated.',
  webhook: 'Webhook',
  webhook_configuration: 'WebHook Configuration',
  webroot_credentials: 'Webroot Endpoint Security',
  webroot_credentials_instructions:
    'Please enter your credentials for <a href="https://identity.webrootanywhere.com/v1/Account/login" target="_blank">Webroot SecureAnywhere</a> account.  This will augment the \'Hosts\' section with additional information and functionality. You can read more about it <a href="https://support.edge.arista.com/hc/en-us/articles/360041456633-Managing-endpoints-via-Webroot-integration" target="_blank">here.</a>',
  webroot_key_tooltip: 'The Parent Keycode used to connect to the Webroot Cloud Platform',
  webroot_parent_keycode: 'Webroot Parent Keycode',
  website: 'Website',
  weekly: 'Weekly',
  widgets_no_appliances: 'NO APPLIANCES ON YOUR ACCOUNT',
  will_renew: 'Will Renew',
  workgroup: 'Workgroup',
  x_others: '+{0} others',
  yes: 'Yes',
  you_must_save_oauth2_settings: 'You must save the OAuth2 settings before running a test.',
  you_must_save_saml_settings: 'You must save the SAML settings before running a test or downloading SP metadata',
  zuora_company_pattern: 'Cannot use pattern of ./',
}

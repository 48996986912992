<template>
  <div class="d-flex flex-column fill-height pa-0">
    <h4 class="mb-2">{{ $t('select_countries') }}</h4>
    <u-grid
      id="policy-manager-geoip"
      selection-type="multiAction"
      row-node-id="value"
      class="fill-height"
      :enable-refresh="false"
      :column-defs="columnDefs"
      :row-data="countryOptions"
      :selection.sync="selectedRows"
      style="height: 400px"
    />
    <slot name="actions" :new-settings="settingsCopy" :disabled="!selectedRows.length" />
  </div>
</template>
<script>
  // list of all the countries used in a select field
  import cloneDeep from 'lodash/cloneDeep'
  import { countryOptions } from 'vuntangle/constants'

  export default {
    props: {
      /**
       * the object data (PolicyJson) expected in the form
       *   id: '<id that should match Policy Id>',
       *   name: '<a name>',
       *   items: ['BE', 'US' ...], - array of country codes
       *   type: <the type as expected on backend>,
       * */
      settings: { type: Object, default: () => {} },
    },
    data() {
      return {
        // clone the settings via spread
        settingsCopy: undefined,
        countryOptions,
        selectedRows: [],
      }
    },
    computed: {
      columnDefs() {
        return [
          {
            headerName: this.$t('countries'),
            field: 'text',
          },
        ]
      },
    },
    watch: {
      settings: {
        handler(settings) {
          this.settingsCopy = cloneDeep(settings)
          this.selectedRows = [...this.settingsCopy.items]
        },
        immediate: true,
        deep: true,
      },
      selectedRows: {
        handler(rows) {
          this.settingsCopy.items = rows.map(item => item.value)
        },
      },
    },
  }
</script>

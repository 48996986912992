<template>
  <div v-if="ruleCopy">
    <rule
      ref="rule"
      :title="`${ruleCopy.ruleId ? $t('edit_rule') : $t('add_rule')}`"
      :rule="ruleCopy"
      :rule-type="ruleType"
      :features="features"
      classic-view
    >
      <template #actions="{ updatedRule }">
        <v-btn text class="text-capitalize mr-2" :ripple="false" @click="$emit('close')">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn depressed color="primary" class="text-capitalize" @click="update(updatedRule)">
          {{ ruleCopy.ruleId ? $t('update') : $t('add_rule') }}
        </v-btn>
      </template>
    </rule>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { Rule } from 'vuntangle'
  import { uuidv4 } from '../util'

  export default {
    components: { Rule },

    props: { rule: { type: Object, default: () => {} } },

    data() {
      return {
        ruleType: 'bypass',
        ruleCopy: undefined,
        features: {
          hasManagementIntf: false,
          hasAboveLayer3Conditions: true,
        },
      }
    },

    mounted() {
      this.ruleCopy = cloneDeep(this.rule)
    },

    methods: {
      async update(rule) {
        const isValid = await this.$refs.rule.validate()
        if (!isValid) return

        if (!rule.ruleId) rule.ruleId = uuidv4()
        this.$emit('update', rule)
        this.$emit('close')
      },
    },
  }
</script>

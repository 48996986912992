<template>
  <div v-if="configuration" class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center">
      <h2 class="font-weight-light">{{ $vuntangle.$t('bypass') }}</h2>
      <v-spacer />
      <slot name="actions" :new-settings="configuration.PolicyJson" :is-dirty="isDirty"></slot>
    </div>

    <v-divider class="my-2" />
    <p v-html="$t('bypass_description')" />
    <u-section v-if="!!$slots['extra-fields']">
      <slot name="extra-fields" />
    </u-section>

    <rules-list
      :title="$t('rules')"
      :rule-type="bypassRuleType"
      :rules.sync="configuration.PolicyJson.rules"
      classic-view
      @edit-rule="onAddEditRule"
      @delete-rule="onDeleteRule"
    >
      <template #actions="{}">
        <v-btn depressed color="primary" class="text-capitalize" @click="onAddEditRule(null)">
          {{ $t('add_rule') }}
        </v-btn>
      </template>
    </rules-list>
  </div>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import isEqual from 'lodash/isEqual'
  import { templatesConfig } from 'vuntangle/pm'
  import { RulesList } from 'vuntangle'
  import GlobalBypassRule from './GlobalBypassRule.vue'
  import editorMixin from './editorMixin'

  export default {
    components: { RulesList },
    mixins: [editorMixin],

    data() {
      return {
        configuration: undefined,
        bypassRuleType: 'bypass',
      }
    },

    computed: {
      config: ({ configuration }) => templatesConfig[configuration.Type],
      isDirty: ({ configuration, editConfiguration }) => !isEqual(configuration, editConfiguration),
    },

    watch: {
      editConfiguration: {
        handler(editConfiguration) {
          if (!editConfiguration) return
          // populates the configuration with temporary `editConfiguration` (see _editorsMixin)
          this.configuration = cloneDeep(this.editConfiguration)
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      onAddEditRule(ruleId) {
        let rule = {
          action: {
            type: 'BYPASS',
          },
          conditions: [],
          description: '',
          enabled: true,
          ruleId: '',
          log: false,
        }
        if (ruleId) {
          rule = this.configuration.PolicyJson.rules.find(rule => rule.ruleId === ruleId)
        }
        this.$vuntangle.dialog.show({
          title: this.$t('bypass'),
          edge: true,
          width: '90vw',
          height: '100%',
          buttons: [],
          componentProps: {
            rule,
          },
          component: GlobalBypassRule,
          componentEvents: {
            // add or update the rule
            update: updatedRule => {
              const ruleIndex = this.configuration.PolicyJson.rules.findIndex(
                rule => rule.ruleId === updatedRule.ruleId,
              )
              if (ruleIndex >= 0) {
                this.configuration.PolicyJson.rules.splice(ruleIndex, 1, updatedRule)
              } else {
                this.configuration.PolicyJson.rules.push(updatedRule)
              }
            },
          },
        })
      },

      onDeleteRule(ruleId) {
        const ruleIndex = this.configuration.PolicyJson.rules.findIndex(rule => rule.ruleId === ruleId)
        if (ruleIndex >= 0) {
          this.configuration.PolicyJson.rules.splice(ruleIndex, 1)
        }
      },
    },
  }
</script>

<template>
  <div class="d-flex align-center">
    <h4 class="mr-2">{{ $t('action') }}</h4>
    <ValidationProvider v-slot="{ errors }" rules="required">
      <v-select
        v-model="action.type"
        data-testid="securityAction"
        :items="items"
        :placeholder="$vuntangle.$t('select')"
        :error-messages="errors"
        :menu-props="{ offsetY: true }"
        outlined
        dense
        hide-details
        style="width: 400px"
      >
        <template #selection="{ item }">
          {{ item.text }}
        </template>

        <template #item="{ item }">
          {{ item.text }}
        </template>
        <template v-if="errors.length" #append><u-errors-tooltip :errors="errors" /></template>
      </v-select>
    </ValidationProvider>
  </div>
</template>
<script>
  export default {
    inject: ['$action'],
    computed: {
      action: ({ $action }) => $action(),
      items: ({ $i18n }) => {
        return [
          {
            text: $i18n.t(`rule_action_accept`),
            value: 'ACCEPT',
            icon: 'mdi-shield-off',
            color: 'red',
          },
          {
            text: $i18n.t(`rule_action_reject`),
            value: 'REJECT',
            icon: 'mdi-shield-alert',
            color: 'orange',
          },
          {
            text: $i18n.t(`rule_action_drop`),
            value: 'DROP',
            icon: 'mdi-shield-check',
            color: 'green',
          },
        ]
      },
    },
  }
</script>
